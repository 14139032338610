module.exports = [
  'Abbeville',
  'Aberdeen',
  'Aberdeen',
  'Aberdeen',
  'Aberdeen',
  'Abilene',
  'Abilene',
  'Abingdon',
  'Abington',
  'Abington',
  'Absecon',
  'Accokeek',
  'Acton',
  'Acushnet',
  'Acworth',
  'Ada',
  'Adams',
  'Addison',
  'Addison',
  'Adelanto',
  'Adelphi',
  'Adrian',
  'Affton',
  'Agawam',
  'Agoura Hills',
  'Ahuimanu',
  'Aiea',
  'Aiken',
  'Air Force Academy',
  'Airmont',
  'Akron',
  'Alabaster',
  'Alachua',
  'Alameda',
  'Alamo',
  'Alamo',
  'Alamo Heights',
  'Alamogordo',
  'Alamosa',
  'Albany',
  'Albany',
  'Albany',
  'Albany',
  'Albemarle',
  'Albert Lea',
  'Albertville',
  'Albion',
  'Albion',
  'Albion',
  'Albuquerque',
  'Alcoa',
  'Alden',
  'Alderwood Manor',
  'Aldine',
  'Alexander City',
  'Alexandria',
  'Alexandria',
  'Alexandria',
  'Alexandria',
  'Alexandria',
  'Algonquin',
  'Alhambra',
  'Alice',
  'Aliquippa',
  'Aliso Viejo',
  'Allegany',
  'Allen',
  'Allen Park',
  'Allendale',
  'Allendale',
  'Allentown',
  'Alliance',
  'Alliance',
  'Allouez',
  'Alma',
  'Aloha',
  'Alondra Park',
  'Alpena',
  'Alpharetta',
  'Alpine',
  'Alpine',
  'Alsip',
  'Alta Sierra',
  'Altadena',
  'Altamont',
  'Altamont',
  'Altamonte Springs',
  'Alton',
  'Altoona',
  'Altoona',
  'Altoona',
  'Altus',
  'Alum Rock',
  'Alvin',
  'Amarillo',
  'Ambler',
  'Ambridge',
  'American Canyon',
  'American Fork',
  'Americus',
  'Ames',
  'Amesbury',
  'Amesbury',
  'Amherst',
  'Amherst',
  'Amherst',
  'Amherst',
  'Amherst Center',
  'Amityville',
  'Ammon',
  'Amory',
  'Amsterdam',
  'Anaconda-Deer Lodge County',
  'Anacortes',
  'Anadarko',
  'Anaheim',
  'Anchorage',
  'Andalusia',
  'Anderson',
  'Anderson',
  'Anderson',
  'Anderson Mill',
  'Andover',
  'Andover',
  'Andover',
  'Andover',
  'Andover',
  'Andrews',
  'Andrews AFB',
  'Angleton',
  'Angola',
  'Ankeny',
  'Ann Arbor',
  'Annandale',
  'Annapolis',
  'Anniston',
  'Anoka',
  'Ansonia',
  'Ansonia',
  'Anthony',
  'Antigo',
  'Antioch',
  'Antioch',
  'Apache Junction',
  'Apex',
  'Apollo Beach',
  'Apopka',
  'Apple Valley',
  'Apple Valley',
  'Appleton',
  'Applewood',
  'Aptos',
  'Aquia Harbour',
  'Arab',
  'Arabi',
  'Aransas Pass',
  'Arbutus',
  'Arcadia',
  'Arcadia',
  'Arcadia',
  'Arcata',
  'Archbald',
  'Archdale',
  'Arden Hills',
  'Arden-Arcade',
  'Ardmore',
  'Ardmore',
  'Arkadelphia',
  'Arkansas City',
  'Arlington',
  'Arlington',
  'Arlington',
  'Arlington',
  'Arlington',
  'Arlington',
  'Arlington Heights',
  'Arnold',
  'Arnold',
  'Arroyo Grande',
  'Artesia',
  'Artesia',
  'Artondale',
  'Arvada',
  'Arvin',
  'Asbury Park',
  'Asheboro',
  'Asheville',
  'Ashland',
  'Ashland',
  'Ashland',
  'Ashland',
  'Ashland',
  'Ashland',
  'Ashland',
  'Ashland',
  'Ashtabula',
  'Ashwaubenon',
  'Aspen Hill',
  'Astoria',
  'Atascadero',
  'Atascocita',
  'Atchison',
  'Athens',
  'Athens',
  'Athens',
  'Athens',
  'Athens-Clarke County',
  'Atherton',
  'Athol',
  'Athol',
  'Atkinson',
  'Atlanta',
  'Atlantic',
  'Atlantic Beach',
  'Atlantic City',
  'Atmore',
  'Attalla',
  'Attica',
  'Attleboro',
  'Atwater',
  'Auburn',
  'Auburn',
  'Auburn',
  'Auburn',
  'Auburn',
  'Auburn',
  'Auburn',
  'Auburn',
  'Auburn Hills',
  'Auburndale',
  'Audubon',
  'Audubon',
  'August',
  'Augusta',
  'Augusta',
  'Augusta-Richmond County',
  'Aurora',
  'Aurora',
  'Aurora',
  'Aurora',
  'Aurora',
  'Austin',
  'Austin',
  'Austintown',
  'Avenal',
  'Avenel',
  'Aventura',
  'Avocado Heights',
  'Avon',
  'Avon',
  'Avon',
  'Avon',
  'Avon Lake',
  'Avon Park',
  'Avondale',
  'Ayer',
  'Azalea Park',
  'Azle',
  'Aztec',
  'Azusa',
  'Babylon',
  'Babylon',
  'Back Mountain',
  'Bacliff',
  'Bailey’s Crossroads',
  'Bainbridge',
  'Bainbridge Island',
  'Baker',
  'Baker City',
  'Bakersfield',
  'Balch Springs',
  'Baldwin',
  'Baldwin',
  'Baldwin Harbor',
  'Baldwin Park',
  'Baldwinsville',
  'Ballenger Creek',
  'Ballston',
  'Ballwin',
  'Baltimore',
  'Bangor',
  'Bangor Trident Base',
  'Banning',
  'Baraboo',
  'Barberton',
  'Barclay-Kingston',
  'Bardstown',
  'Barnhart',
  'Barnstable Town',
  'Barre',
  'Barre',
  'Barrington',
  'Barrington',
  'Barrington',
  'Barrington',
  'Barrington',
  'Barstow',
  'Bartlesville',
  'Bartlett',
  'Bartlett',
  'Barton',
  'Bartonville',
  'Bartow',
  'Bastrop',
  'Batavia',
  'Batavia',
  'Batesville',
  'Batesville',
  'Batesville',
  'Bath',
  'Bath',
  'Baton Rouge',
  'Battle Creek',
  'Battle Ground',
  'Bay City',
  'Bay City',
  'Bay Minette',
  'Bay Point',
  'Bay Shore',
  'Bay St. Louis',
  'Bay Village',
  'Bayonet Point',
  'Bayonne',
  'Bayou Cane',
  'Bayport',
  'Bayshore Gardens',
  'Baytown',
  'Bayville',
  'Baywood',
  'Baywood-Los Osos',
  'Beach Park',
  'Beachwood',
  'Beachwood',
  'Beacon',
  'Beacon Square',
  'Bear',
  'Beatrice',
  'Beaufort',
  'Beaumont',
  'Beaumont',
  'Beaver Dam',
  'Beaver Falls',
  'Beavercreek',
  'Beaverton',
  'Beckett Ridge',
  'Beckley',
  'Bedford',
  'Bedford',
  'Bedford',
  'Bedford',
  'Bedford',
  'Bedford',
  'Bedford',
  'Bedford Heights',
  'Bee Ridge',
  'Beech Grove',
  'Beecher',
  'Beekman',
  'Beeville',
  'Bel Air',
  'Bel Air North',
  'Bel Air South',
  'Belchertown',
  'Belen',
  'Belfast',
  'Bell',
  'Bell Gardens',
  'Bella Vista',
  'Bellair-Meadowbrook Terrace',
  'Bellaire',
  'Bellbrook',
  'Belle Chasse',
  'Belle Glade',
  'Belle Haven',
  'Bellefontaine',
  'Bellefontaine Neighbors',
  'Bellefonte',
  'Belleville',
  'Belleville',
  'Bellevue',
  'Bellevue',
  'Bellevue',
  'Bellevue',
  'Bellevue',
  'Bellevue',
  'Bellevue Town',
  'Bellflower',
  'Bellingham',
  'Bellingham',
  'Bellmawr',
  'Bellmead',
  'Bellmore',
  'Bellview',
  'Bellwood',
  'Belmar',
  'Belmont',
  'Belmont',
  'Belmont',
  'Belmont',
  'Belmont',
  'Beloit',
  'Beloit',
  'Belpre',
  'Belton',
  'Belton',
  'Beltsville',
  'Belvedere Park',
  'Belvidere',
  'Bemidji',
  'Benbrook',
  'Bend',
  'Benicia',
  'Bennettsville',
  'Bennington',
  'Bennington',
  'Bennsville',
  'Bensenville',
  'Benton',
  'Benton',
  'Benton Harbor',
  'Bentonville',
  'Berea',
  'Berea',
  'Berea',
  'Bergenfield',
  'Berkeley',
  'Berkeley',
  'Berkeley Heights',
  'Berkley',
  'Berkley',
  'Berlin',
  'Berlin',
  'Berlin',
  'Bermuda Dunes',
  'Bernalillo',
  'Bernardsville',
  'Berwick',
  'Berwick',
  'Berwyn',
  'Bessemer',
  'Bethalto',
  'Bethany',
  'Bethel',
  'Bethel',
  'Bethel Park',
  'Bethesda',
  'Bethlehem',
  'Bethlehem',
  'Bethpage',
  'Bettendorf',
  'Beverly',
  'Beverly Hills',
  'Beverly Hills',
  'Beverly Hills',
  'Bexley',
  'Biddeford',
  'Big Flats',
  'Big Lake',
  'Big Rapids',
  'Big Spring',
  'Billerica',
  'Billings',
  'Biloxi',
  'Binghamton',
  'Birmingham',
  'Birmingham',
  'Bisbee',
  'Bismarck',
  'Bixby',
  'Black Forest',
  'Black Jack',
  'Black Mountain',
  'Blackfoot',
  'Blackhawk-Camino Tassajara',
  'Blacklick Estates',
  'Blacksburg',
  'Blackstone',
  'Blackwell',
  'Bladensburg',
  'Blaine',
  'Blair',
  'Blakely',
  'Bloomfield',
  'Bloomfield',
  'Bloomfield',
  'Bloomfield Township',
  'Blooming Grove',
  'Bloomingdale',
  'Bloomingdale',
  'Bloomingdale',
  'Bloomingdale',
  'Bloomington',
  'Bloomington',
  'Bloomington',
  'Bloomington',
  'Bloomsburg',
  'Blue Ash',
  'Blue Bell',
  'Blue Island',
  'Blue Springs',
  'Bluefield',
  'Bluffton',
  'Blythe',
  'Blytheville',
  'Boardman',
  'Boaz',
  'Boca Del Mar',
  'Boca Raton',
  'Boerne',
  'Bogalusa',
  'Bogota',
  'Bohemia',
  'Boise City',
  'Bolingbrook',
  'Bolivar',
  'Bon Air',
  'Bonadelle Ranchos-Madera Ranchos',
  'Bonham',
  'Bonita',
  'Bonita Springs',
  'Bonner Springs',
  'Bonney Lake',
  'Boone',
  'Boone',
  'Booneville',
  'Boonton',
  'Boonville',
  'Boonville',
  'Borger',
  'Bossier City',
  'Boston',
  'Boston',
  'Bostonia',
  'Bothell',
  'Boulder',
  'Boulder City',
  'Boulder Hill',
  'Bound Brook',
  'Bountiful',
  'Bourbonnais',
  'Bourne',
  'Bow',
  'Bowie',
  'Bowleys Quarters',
  'Bowling Green',
  'Bowling Green',
  'Boxford',
  'Boyes Hot Springs',
  'Boynton Beach',
  'Bozeman',
  'Bradenton',
  'Bradford',
  'Bradley',
  'Brainerd',
  'Braintree',
  'Braintree',
  'Brandon',
  'Brandon',
  'Branford',
  'Branson',
  'Brattleboro',
  'Brattleboro',
  'Brawley',
  'Brazil',
  'Brea',
  'Breaux Bridge',
  'Brecksville',
  'Bremerton',
  'Brenham',
  'Brent',
  'Brentwood',
  'Brentwood',
  'Brentwood',
  'Brentwood',
  'Brentwood',
  'Brevard',
  'Brewer',
  'Brewster',
  'Briarcliff Manor',
  'Bridge City',
  'Bridge City',
  'Bridgeport',
  'Bridgeport',
  'Bridgeport',
  'Bridgeport',
  'Bridgeton',
  'Bridgeton',
  'Bridgetown North',
  'Bridgeview',
  'Bridgewater',
  'Bridgewater',
  'Brier',
  'Brigantine',
  'Brigham City',
  'Brighton',
  'Brighton',
  'Brighton',
  'Brighton',
  'Bristol',
  'Bristol',
  'Bristol',
  'Bristol',
  'Bristol',
  'Bristol',
  'Bristol',
  'Broadview',
  'Broadview Heights',
  'Broadview Park',
  'Brockport',
  'Brockton',
  'Broken Arrow',
  'Bronxville',
  'Brook Park',
  'Brookfield',
  'Brookfield',
  'Brookfield',
  'Brookfield',
  'Brookhaven',
  'Brookhaven',
  'Brookhaven',
  'Brookings',
  'Brookline',
  'Brookline',
  'Brooklyn',
  'Brooklyn',
  'Brooklyn Center',
  'Brooklyn Park',
  'Brooklyn Park',
  'Brookside',
  'Brooksville',
  'Broomall',
  'Broomfield',
  'Brown Deer',
  'Brownfield',
  'Browns Mills',
  'Brownsburg',
  'Brownsville',
  'Brownsville',
  'Brownsville',
  'Brownsville-Bawcomville',
  'Brownwood',
  'Brunswick',
  'Brunswick',
  'Brunswick',
  'Brunswick',
  'Brunswick',
  'Brushy Creek',
  'Bryan',
  'Bryan',
  'Bryant',
  'Bryn Mawr-Skyway',
  'Buckeye',
  'Bucyrus',
  'Budd Lake',
  'Buechel',
  'Buena Park',
  'Buena Vista',
  'Buena Vista',
  'Buffalo',
  'Buffalo',
  'Buffalo Grove',
  'Buford',
  'Bull Run',
  'Bullhead City',
  'Burbank',
  'Burbank',
  'Burien',
  'Burkburnett',
  'Burke',
  'Burleson',
  'Burley',
  'Burlingame',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burlington',
  'Burnsville',
  'Burr Ridge',
  'Burrillville',
  'Burton',
  'Burton',
  'Burtonsville',
  'Busti',
  'Butler',
  'Butler',
  'Butte-Silver Bow',
  'Buxton',
  'Byram',
  'Cabot',
  'Cadillac',
  'Cahokia',
  'Cairo',
  'Cairo',
  'Calabasas',
  'Caldwell',
  'Caldwell',
  'Caledonia',
  'Calexico',
  'Calhoun',
  'California City',
  'Calimesa',
  'Calipatria',
  'Callaway',
  'Calumet City',
  'Calumet Park',
  'Calverton',
  'Camano',
  'Camarillo',
  'Camas',
  'Cambria',
  'Cambridge',
  'Cambridge',
  'Cambridge',
  'Camden',
  'Camden',
  'Camden',
  'Cameron',
  'Cameron Park',
  'Camillus',
  'Camp Hill',
  'Camp Pendleton North',
  'Camp Pendleton South',
  'Camp Springs',
  'Camp Verde',
  'Campbell',
  'Campbell',
  'Campbellsville',
  'Canandaigua',
  'Canandaigua',
  'Canby',
  'Candler-McAfee',
  'Canfield',
  'Canon City',
  'Canonsburg',
  'Canton',
  'Canton',
  'Canton',
  'Canton',
  'Canton',
  'Canton',
  'Canton',
  'Canton',
  'Canyon',
  'Canyon Lake',
  'Canyon Lake',
  'Canyon Rim',
  'Cape Canaveral',
  'Cape Coral',
  'Cape Elizabeth',
  'Cape Girardeau',
  'Cape St. Claire',
  'Capitola',
  'Carbondale',
  'Carbondale',
  'Carencro',
  'Caribou',
  'Carlisle',
  'Carlsbad',
  'Carlsbad',
  'Carmel',
  'Carmel',
  'Carmichael',
  'Carnegie',
  'Carney',
  'Carneys Point',
  'Carnot-Moon',
  'Carol City',
  'Carol Stream',
  'Carpentersville',
  'Carpinteria',
  'Carrboro',
  'Carroll',
  'Carrollton',
  'Carrollton',
  'Carrollton',
  'Carson',
  'Carson City',
  'Carteret',
  'Cartersville',
  'Carthage',
  'Carthage',
  'Caruthersville',
  'Carver',
  'Cary',
  'Cary',
  'Casa de Oro-Mount Helix',
  'Casa Grande',
  'Casas Adobes',
  'Cascade-Fairwood',
  'Casper',
  'Casselberry',
  'Castle Rock',
  'Castle Shannon',
  'Castlewood',
  'Castro Valley',
  'Castroville',
  'Catalina',
  'Catalina Foothills',
  'Catasauqua',
  'Cathedral City',
  'Catonsville',
  'Catskill',
  'Cave Spring',
  'Cayce',
  'Cazenovia',
  'Cedar City',
  'Cedar Falls',
  'Cedar Grove',
  'Cedar Hill',
  'Cedar Hills',
  'Cedar Lake',
  'Cedar Mill',
  'Cedar Park',
  'Cedar Rapids',
  'Cedarburg',
  'Cedarhurst',
  'Cedartown',
  'Celina',
  'Center Line',
  'Center Moriches',
  'Center Point',
  'Centereach',
  'Centerville',
  'Centerville',
  'Central Falls',
  'Central Islip',
  'Central Manchester',
  'Central Point',
  'Centralia',
  'Centralia',
  'Centreville',
  'Century Village',
  'Ceres',
  'Cerritos',
  'Chalco',
  'Chalmette',
  'Chambersburg',
  'Chamblee',
  'Champaign',
  'Champlin',
  'Chandler',
  'Chanhassen',
  'Channahon',
  'Channelview',
  'Chantilly',
  'Chanute',
  'Chaparral',
  'Chapel Hill',
  'Chappaqua',
  'Charles City',
  'Charleston',
  'Charleston',
  'Charleston',
  'Charlestown',
  'Charlotte',
  'Charlotte',
  'Charlottesville',
  'Charlton',
  'Charter Oak',
  'Chaska',
  'Chatham',
  'Chatham',
  'Chatham',
  'Chattanooga',
  'Cheat Lake',
  'Cheektowaga',
  'Cheektowaga',
  'Chehalis',
  'Chelmsford',
  'Chelsea',
  'Chenango',
  'Cheney',
  'Cherry Hill Mall',
  'Cherryland',
  'Chesapeake',
  'Chesapeake Ranch Estates-Drum Point',
  'Cheshire',
  'Chester',
  'Chester',
  'Chester',
  'Chester',
  'Chesterfield',
  'Chesterton',
  'Chestnut Ridge',
  'Cheval',
  'Cheverly',
  'Cheviot',
  'Chevy Chase',
  'Cheyenne',
  'Chicago',
  'Chicago Heights',
  'Chicago Ridge',
  'Chickasaw',
  'Chickasha',
  'Chico',
  'Chicopee',
  'Childress',
  'Chili',
  'Chillicothe',
  'Chillicothe',
  'Chillum',
  'Chino',
  'Chino Hills',
  'Chino Valley',
  'Chippewa Falls',
  'Choctaw',
  'Chowchilla',
  'Christiansburg',
  'Chubbuck',
  'Chula Vista',
  'Cicero',
  'Cicero',
  'Cimarron Hills',
  'Cincinnati',
  'Cinco Ranch',
  'Circleville',
  'Citrus',
  'Citrus Heights',
  'Citrus Park',
  'Citrus Ridge',
  'City of The Dalles',
  'Claiborne',
  'Clairton',
  'Clanton',
  'Claremont',
  'Claremont',
  'Claremore',
  'Clarence',
  'Clarendon Hills',
  'Clarion',
  'Clark',
  'Clarksburg',
  'Clarksdale',
  'Clarkson',
  'Clarkston',
  'Clarkston',
  'Clarkston Heights-Vineland',
  'Clarkstown',
  'Clarksville',
  'Clarksville',
  'Clarksville',
  'Claverack',
  'Clawson',
  'Clay',
  'Claymont',
  'Clayton',
  'Clayton',
  'Clayton',
  'Clayton',
  'Clayton',
  'Clear Lake',
  'Clearfield',
  'Clearfield',
  'Clearlake',
  'Clearwater',
  'Cleburne',
  'Clemmons',
  'Clemson',
  'Clermont',
  'Cleveland',
  'Cleveland',
  'Cleveland',
  'Cleveland',
  'Cleveland Heights',
  'Clewiston',
  'Cliffside Park',
  'Clifton',
  'Clifton',
  'Clifton Heights',
  'Clifton Park',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clinton',
  'Clive',
  'Cloquet',
  'Closter',
  'Cloverdale',
  'Cloverleaf',
  'Cloverly',
  'Clovis',
  'Clovis',
  'Clute',
  'Clyde',
  'Coachella',
  'Coalinga',
  'Coatesville',
  'Cobleskill',
  'Cochituate',
  'Cockeysville',
  'Cocoa',
  'Cocoa Beach',
  'Coconut Creek',
  'Cody',
  'Coeur d’Alene',
  'Coeymans',
  'Coffeyville',
  'Cohasset',
  'Cohoes',
  'Colchester',
  'Colchester',
  'Coldwater',
  'Colesville',
  'College',
  'College Park',
  'College Park',
  'College Place',
  'College Station',
  'Collegedale',
  'Collegeville',
  'Colleyville',
  'Collier Manor-Cresthaven',
  'Collierville',
  'Collingdale',
  'Collingswood',
  'Collins',
  'Collinsville',
  'Collinsville',
  'Colonia',
  'Colonial Heights',
  'Colonial Heights',
  'Colonial Park',
  'Colonie',
  'Colonie',
  ' Springs',
  'Colton',
  'Columbia',
  'Columbia',
  'Columbia',
  'Columbia',
  'Columbia',
  'Columbia',
  'Columbia',
  'Columbia City',
  'Columbia Heights',
  'Columbine',
  'Columbus',
  'Columbus',
  'Columbus',
  'Columbus',
  'Columbus',
  'Commack',
  'Commerce',
  'Commerce',
  'Commerce City',
  'Compton',
  'Comstock Park',
  'Concord',
  'Concord',
  'Concord',
  'Concord',
  'Concord',
  'Concord',
  'Congers',
  'Conley',
  'Conneaut',
  'Connellsville',
  'Connersville',
  'Conning Towers-Nautilus Park',
  'Conover',
  'Conroe',
  'Conshohocken',
  'Converse',
  'Conway',
  'Conway',
  'Conway',
  'Conway',
  'Conyers',
  'Cookeville',
  'Coolidge',
  'Coon Rapids',
  'Cooper City',
  'Coos Bay',
  'Copiague',
  'Coppell',
  'Copperas Cove',
  'Coral Gables',
  'Coral Hills',
  'Coral Springs',
  'Coral Terrace',
  'Coralville',
  'Coram',
  'Coraopolis',
  'Corbin',
  'Corcoran',
  'Cordele',
  'Corinth',
  'Corinth',
  'Cornelius',
  'Cornelius',
  'Corning',
  'Corning',
  'Corning',
  'Cornwall',
  'Corona',
  'Coronado',
  'Corpus Christi',
  'Corrales',
  'Corry',
  'Corsicana',
  'Corte Madera',
  'Cortez',
  'Cortland',
  'Cortland',
  'Cortlandt',
  'Cortlandville',
  'Corvallis',
  'Coshocton',
  'Costa Mesa',
  'Cotati',
  'Coto de Caza',
  'Cottage Grove',
  'Cottage Grove',
  'Cottage Lake',
  'Cottonwood',
  'Cottonwood Heights',
  'Cottonwood West',
  'Cottonwood-Verde Village',
  'Council Bluffs',
  'Country Club',
  'Country Club',
  'Country Club Estates',
  'Country Club Hills',
  'Country Walk',
  'Covedale',
  'Coventry',
  'Coventry',
  'Covina',
  'Covington',
  'Covington',
  'Covington',
  'Covington',
  'Covington',
  'Covington',
  'Coweta',
  'Coxsackie',
  'Crafton',
  'Craig',
  'Cranford',
  'Cranston',
  'Crawford',
  'Crawfordsville',
  'Cresskill',
  'Crest Hill',
  'Crestline',
  'Creston',
  'Crestview',
  'Crestwood',
  'Crestwood',
  'Crestwood Village',
  'Crete',
  'Crete',
  'Creve Coeur',
  'Crockett',
  'Crofton',
  'Cromwell',
  'Crookston',
  'Cross Lanes',
  'Crossett',
  'Crossville',
  'Croton-on-Hudson',
  'Crowley',
  'Crowley',
  'Crown Point',
  'Croydon',
  'Crystal',
  'Crystal City',
  'Crystal Lake',
  'Cudahy',
  'Cudahy',
  'Cuero',
  'Cullman',
  'Culpeper',
  'Culver City',
  'Cumberland',
  'Cumberland',
  'Cumberland',
  'Cumberland Hill',
  'Cupertino',
  'Cushing',
  'Cutler',
  'Cutler Ridge',
  'Cutlerville',
  'Cuyahoga Falls',
  'Cynthiana',
  'Cypress',
  'Cypress Gardens',
  'Cypress Lake',
  'D’Iberville',
  'Dade City',
  'Dale City',
  'Dalhart',
  'Dallas',
  'Dallas',
  'Dalton',
  'Dalton',
  'Daly City',
  'Damascus',
  'Dana Point',
  'Danbury',
  'Danbury',
  'Dania Beach',
  'Danvers',
  'Danvers',
  'Danville',
  'Danville',
  'Danville',
  'Danville',
  'Danville',
  'Daphne',
  'Darby',
  'Darby Township',
  'Darien',
  'Darien',
  'Darien',
  'Darlington',
  'Darnestown',
  'Dartmouth',
  'Davenport',
  'Davidson',
  'Davie',
  'Davis',
  'Dayton',
  'Dayton',
  'Dayton',
  'Daytona Beach',
  'De Bary',
  'De Land',
  'De Pere',
  'De Ridder',
  'De Soto',
  'De Witt',
  'Dearborn',
  'Dearborn Heights',
  'Decatur',
  'Decatur',
  'Decatur',
  'Decatur',
  'Decorah',
  'Dedham',
  'Dedham',
  'Deer Park',
  'Deer Park',
  'Deerfield',
  'Deerfield Beach',
  'Deerpark',
  'Defiance',
  'DeForest',
  'DeKalb',
  'Del Aire',
  'Del City',
  'Del Rio',
  'Delafield',
  'Delafield',
  'Delano',
  'Delavan',
  'Delhi',
  'Delmar',
  'Delphos',
  'Delray Beach',
  'Delta',
  'Deltona',
  'Deming',
  'Demopolis',
  'Denham Springs',
  'Denison',
  'Denison',
  'Dennis',
  'Dent',
  'Denton',
  'Dentsville',
  'Denver',
  'Depew',
  'Derby',
  'Derby',
  'Derby',
  'Derby',
  'Derry',
  'Derry',
  'Des Moines',
  'Des Moines',
  'Des Peres',
  'Des Plaines',
  'Desert Hot Springs',
  'DeSoto',
  'Destin',
  'Destrehan',
  'Detroit',
  'Detroit Lakes',
  'Devils Lake',
  'Dewey-Humboldt',
  'Dexter',
  'Diamond Bar',
  'Dickinson',
  'Dickinson',
  'Dickson',
  'Dickson City',
  'Dighton',
  'Dillon',
  'Dinuba',
  'Discovery Bay',
  'Dishman',
  'Dix Hills',
  'Dixon',
  'Dixon',
  'Dobbs Ferry',
  'Dock Junction',
  'Doctor Phillips',
  'Dodge City',
  'Dolton',
  'Donaldsonville',
  'Donna',
  'Doral',
  'Doraville',
  'Dormont',
  'Dothan',
  'Douglas',
  'Douglas',
  'Douglas',
  'Douglasville',
  'Dover',
  'Dover',
  'Dover',
  'Dover',
  'Dover',
  'Dowagiac',
  'Downers Grove',
  'Downey',
  'Downingtown',
  'Doylestown',
  'Dracut',
  'Draper',
  'Drexel Heights',
  'Drexel Hill',
  'Druid Hills',
  'Dry Run',
  'Dryden',
  'Du Quoin',
  'Duarte',
  'Dublin',
  'Dublin',
  'Dublin',
  'DuBois',
  'Dubuque',
  'Dudley',
  'Duluth',
  'Duluth',
  'Dumas',
  'Dumbarton',
  'Dumont',
  'Dunbar',
  'Duncan',
  'Duncanville',
  'Dundalk',
  'Dunedin',
  'Dunellen',
  'Dunkirk',
  'Dunmore',
  'Dunn',
  'Dunn Loring',
  'Dunwoody',
  'Duquesne',
  'Durango',
  'Durant',
  'Durham',
  'Durham',
  'Durham',
  'Durham',
  'Duxbury',
  'Dyer',
  'Dyersburg',
  'Eagan',
  'Eagle',
  'Eagle Mountain',
  'Eagle Pass',
  'Earlimart',
  'Easley',
  'East Alton',
  'East Aurora',
  'East Bethel',
  'East Brainerd',
  'East Bridgewater',
  'East Brunswick',
  'East Chicago',
  'East Cleveland',
  'East Compton',
  'East Falmouth',
  'East Fishkill',
  'East Foothills',
  'East Glenville',
  'East Grand Forks',
  'East Grand Rapids',
  'East Greenbush',
  'East Greenwich',
  'East Haddam',
  'East Hampton',
  'East Hampton',
  'East Hartford',
  'East Hartford',
  'East Haven',
  'East Haven',
  'East Hemet',
  'East Highland Park',
  'East Hill-Meridian',
  'East Hills',
  'East Islip',
  'East La Mirada',
  'East Lake',
  'East Lansing',
  'East Liverpool',
  'East Longmeadow',
  'East Los Angeles',
  'East Lyme',
  'East Massapequa',
  'East Meadow',
  'East Millcreek',
  'East Moline',
  'East Norriton',
  'East Northport',
  'East Orange',
  'East Palo Alto',
  'East Pasadena',
  'East Patchogue',
  'East Peoria',
  'East Perrine',
  'East Point',
  'East Porterville',
  'East Providence',
  'East Renton Highlands',
  'East Ridge',
  'East Riverdale',
  'East Rochester',
  'East Rochester',
  'East Rockaway',
  'East Rutherford',
  'East San Gabriel',
  'East St. Louis',
  'East Stroudsburg',
  'East Wenatchee Bench',
  'East Windsor',
  'East York',
  'Eastchester',
  'Eastchester',
  'Easthampton',
  'Eastlake',
  'Easton',
  'Easton',
  'Easton',
  'Easton',
  'Eastpointe',
  'Eastwood',
  'Eaton',
  'Eatonton',
  'Eatontown',
  'Eau Claire',
  'Echelon',
  'Economy',
  'Ecorse',
  'Eden',
  'Eden',
  'Eden Isle',
  'Eden Prairie',
  'Edgemere',
  'Edgewater',
  'Edgewater',
  'Edgewood',
  'Edgewood',
  'Edgewood',
  'Edina',
  'Edinboro',
  'Edinburg',
  'Edison',
  'Edmond',
  'Edmonds',
  'Edwards',
  'Edwardsville',
  'Effingham',
  'Eglin AFB',
  'Egypt Lake-Leto',
  'Eidson Road',
  'El Cajon',
  'El Campo',
  'El Centro',
  'El Cerrito',
  'El Dorado',
  'El Dorado',
  'El Dorado Hills',
  'El Mirage',
  'El Monte',
  'El Paso',
  'El Paso de Robles',
  'El Reno',
  'El Rio',
  'El Segundo',
  'El Sobrante',
  'Elbridge',
  'Eldersburg',
  'Elfers',
  'Elgin',
  'Elizabeth',
  'Elizabeth City',
  'Elizabethton',
  'Elizabethtown',
  'Elizabethtown',
  'Elk City',
  'Elk Grove',
  'Elk Grove Village',
  'Elk Plain',
  'Elk River',
  'Elkhart',
  'Elkhorn',
  'Elkhorn',
  'Elkins',
  'Elko',
  'Elkridge',
  'Elkton',
  'Ellensburg',
  'Ellicott',
  'Ellicott City',
  'Ellington',
  'Ellisville',
  'Ellsworth',
  'Ellwood City',
  'Elm Grove',
  'Elma',
  'Elmhurst',
  'Elmira',
  'Elmira',
  'Elmont',
  'Elmwood Park',
  'Elmwood Park',
  'Elon College',
  'Eloy',
  'Elsmere',
  'Elwood',
  'Elwood',
  'Elyria',
  'Emerson',
  'Emeryville',
  'Emmaus',
  'Emporia',
  'Encinitas',
  'Endicott',
  'Endwell',
  'Enfield',
  'Englewood',
  'Englewood',
  'Englewood',
  'Englewood',
  'Enid',
  'Ennis',
  'Ensley',
  'Enterprise',
  'Enterprise',
  'Enumclaw',
  'Ephrata',
  'Ephrata',
  'Erie',
  'Erie',
  'Erlanger',
  'Erlton-Ellisburg',
  'Erwin',
  'Escanaba',
  'Escondido',
  'Esopus',
  'Espanola',
  'Essex',
  'Essex',
  'Essex',
  'Essex Junction',
  'Estelle',
  'Estero',
  'Estherville',
  'Euclid',
  'Eufaula',
  'Eugene',
  'Euless',
  'Eunice',
  'Eureka',
  'Eureka',
  'Eustis',
  'Evans',
  'Evans',
  'Evans',
  'Evanston',
  'Evanston',
  'Evansville',
  'Everett',
  'Everett',
  'Evergreen',
  'Evergreen',
  'Evergreen Park',
  'Ewa Beach',
  'Ewing',
  'Excelsior Springs',
  'Exeter',
  'Exeter',
  'Exeter',
  'Exeter',
  'Fabens',
  'Fair Lawn',
  'Fair Oaks',
  'Fair Oaks',
  'Fair Plain',
  'Fairbanks',
  'Fairborn',
  'Fairdale',
  'Fairfax',
  'Fairfax',
  'Fairfield',
  'Fairfield',
  'Fairfield',
  'Fairfield',
  'Fairfield',
  'Fairfield',
  'Fairfield',
  'Fairhaven',
  'Fairhope',
  'Fairland',
  'Fairlawn',
  'Fairless Hills',
  'Fairmont',
  'Fairmont',
  'Fairmount',
  'Fairview',
  'Fairview',
  'Fairview',
  'Fairview',
  'Fairview Heights',
  'Fairview Park',
  'Fairview Shores',
  'Fairwood',
  'Fall River',
  'Fallbrook',
  'Fallon',
  'Falls Church',
  'Fallsburg',
  'Fallston',
  'Falmouth',
  'Falmouth',
  'Fanwood',
  'Fargo',
  'Faribault',
  'Farmers Branch',
  'Farmersville',
  'Farmingdale',
  'Farmington',
  'Farmington',
  'Farmington',
  'Farmington',
  'Farmington',
  'Farmington',
  'Farmington',
  'Farmington',
  'Farmington Hills',
  'Farmingville',
  'Farmville',
  'Farragut',
  'Farrell',
  'Fayetteville',
  'Fayetteville',
  'Fayetteville',
  'Fayetteville',
  'Feasterville-Trevose',
  'Federal Heights',
  'Federal Way',
  'Fenton',
  'Fenton',
  'Fergus Falls',
  'Ferguson',
  'Fern Creek',
  'Fern Park',
  'Fernandina Beach',
  'Ferndale',
  'Ferndale',
  'Ferndale',
  'Fernley',
  'Fernway',
  'Ferry Pass',
  'Festus',
  'Fillmore',
  'Findlay',
  'Finneytown',
  'Fishers',
  'Fishkill',
  'Fitchburg',
  'Fitchburg',
  'Fitzgerald',
  'Five Corners',
  'Five Forks',
  'Flagstaff',
  'Flat Rock',
  'Flatwoods',
  'Flint',
  'Floral Park',
  'Florence',
  'Florence',
  'Florence',
  'Florence',
  'Florence',
  'Florence-Graham',
  'Florence-Roebling',
  'Florham Park',
  ' City',
  ' Ridge',
  'Florin',
  'Florissant',
  'Flossmoor',
  'Flower Mound',
  'Flowing Wells',
  'Flushing',
  'Folcroft',
  'Foley',
  'Folsom',
  'Folsom',
  'Fond du Lac',
  'Fontana',
  'Foothill Farms',
  'Foothill Ranch',
  'Fords',
  'Forest',
  'Forest Acres',
  'Forest City',
  'Forest City',
  'Forest Glen',
  'Forest Grove',
  'Forest Hill',
  'Forest Hills',
  'Forest Hills',
  'Forest Lake',
  'Forest Park',
  'Forest Park',
  'Forest Park',
  'Forestdale',
  'Forestville',
  'Forestville',
  'Forrest City',
  'Fort Ann',
  'Fort Atkinson',
  'Fort Belvoir',
  'Fort Benning South',
  'Fort Bliss',
  'Fort Bragg',
  'Fort Bragg',
  'Fort Campbell North',
  'Fort Carson',
  'Fort Collins',
  'Fort Dix',
  'Fort Dodge',
  'Fort Drum',
  'Fort Hood',
  'Fort Hunt',
  'Fort Knox',
  'Fort Lauderdale',
  'Fort Lee',
  'Fort Lee',
  'Fort Leonard Wood',
  'Fort Lewis',
  'Fort Lupton',
  'Fort Madison',
  'Fort Meade',
  'Fort Mill',
  'Fort Mitchell',
  'Fort Morgan',
  'Fort Myers',
  'Fort Myers Beach',
  'Fort Oglethorpe',
  'Fort Payne',
  'Fort Pierce',
  'Fort Pierce North',
  'Fort Polk South',
  'Fort Riley North',
  'Fort Rucker',
  'Fort Salonga',
  'Fort Scott',
  'Fort Smith',
  'Fort Stewart',
  'Fort Stockton',
  'Fort Thomas',
  'Fort Valley',
  'Fort Walton Beach',
  'Fort Washington',
  'Fort Wayne',
  'Fort Worth',
  'Fortuna',
  'Fortuna Foothills',
  'Foster City',
  'Fostoria',
  'Fountain',
  'Fountain Hills',
  'Fountain Inn',
  'Fountain Valley',
  'Fountainbleau',
  'Four Corners',
  'Fox Lake',
  'Fox Point',
  'Foxborough',
  'Framingham',
  'Framingham',
  'Franconia',
  'Frankfort',
  'Frankfort',
  'Frankfort',
  'Frankfort',
  'Frankfort Square',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin',
  'Franklin Lakes',
  'Franklin Park',
  'Franklin Park',
  'Franklin Square',
  'Fraser',
  'Frederick',
  'Fredericksburg',
  'Fredericksburg',
  'Fredonia',
  'Freehold',
  'Freeport',
  'Freeport',
  'Freeport',
  'Freeport',
  'Freetown',
  'Fremont',
  'Fremont',
  'Fremont',
  'Fresno',
  'Fresno',
  'Fridley',
  'Friendly',
  'Friendswood',
  'Frisco',
  'Front Royal',
  'Frostburg',
  'Fruit Cove',
  'Fruita',
  'Fruitvale',
  'Fruitville',
  'Fullerton',
  'Fullerton',
  'Fulton',
  'Fulton',
  'Fultondale',
  'Fuquay-Varina',
  'Gadsden',
  'Gaffney',
  'Gages Lake',
  'Gahanna',
  'Gainesville',
  'Gainesville',
  'Gainesville',
  'Gaithersburg',
  'Galax',
  'Galena Park',
  'Galesburg',
  'Galion',
  'Gallatin',
  'Galliano',
  'Gallup',
  'Galt',
  'Galveston',
  'Gantt',
  'Garden Acres',
  'Garden City',
  'Garden City',
  'Garden City',
  'Garden City',
  'Garden City',
  'Garden City',
  'Garden City Park',
  'Garden Grove',
  'Garden Home-Whitford',
  'Gardena',
  'Gardendale',
  'Gardere',
  'Gardiner',
  'Gardner',
  'Gardner',
  'Gardnerville Ranchos',
  'Garfield',
  'Garfield Heights',
  'Garland',
  'Garner',
  'Garrison',
  'Gary',
  'Gastonia',
  'Gates',
  'Gates-North Gates',
  'Gatesville',
  'Gautier',
  'Geddes',
  'Genesee',
  'Geneseo',
  'Geneseo',
  'Geneseo',
  'Geneva',
  'Geneva',
  'Geneva',
  'Georgetown',
  'Georgetown',
  'Georgetown',
  'Georgetown',
  'Georgetown',
  'Georgetown County',
  'Gering',
  'German Flatts',
  'Germantown',
  'Germantown',
  'Germantown',
  'Gettysburg',
  'Gibsonton',
  'Gifford',
  'Gig Harbor',
  'Gilbert',
  'Gilford',
  'Gillette',
  'Gilroy',
  'Girard',
  'Gladeview',
  'Gladewater',
  'Gladstone',
  'Gladstone',
  'Glasgow',
  'Glasgow',
  'Glassboro',
  'Glastonbury',
  'Glastonbury Center',
  'Glen Allen',
  'Glen Avon',
  'Glen Burnie',
  'Glen Carbon',
  'Glen Cove',
  'Glen Ellyn',
  'Glen Ridge',
  'Glen Rock',
  'Glenarden',
  'Glencoe',
  'Glendale',
  'Glendale',
  'Glendale',
  'Glendale Heights',
  'Glendora',
  'Glenn Dale',
  'Glenn Heights',
  'Glenolden',
  'Glenpool',
  'Glens Falls',
  'Glens Falls North',
  'Glenside',
  'Glenvar Heights',
  'Glenview',
  'Glenville',
  'Glenwood',
  'Glenwood Springs',
  'Globe',
  'Glocester',
  'Gloucester',
  'Gloucester City',
  'Gloucester Point',
  'Gloversville',
  'Godfrey',
  'Goffstown',
  'Gold Camp',
  'Gold River',
  'Golden',
  'Golden Gate',
  'Golden Glades',
  'Golden Hills',
  'Golden Lakes',
  'Golden Valley',
  'Goldenrod',
  'Goldsboro',
  'Goleta',
  'Gonzales',
  'Gonzales',
  'Gonzales',
  'Gonzalez',
  'Goodings Grove',
  'Goodlettsville',
  'Goodyear',
  'Goose Creek',
  'Gorham',
  'Goshen',
  'Goshen',
  'Goulds',
  'Gouverneur',
  'Grafton',
  'Grafton',
  'Graham',
  'Graham',
  'Graham',
  'Granby',
  'Granby',
  'Granby',
  'Grand Blanc',
  'Grand Chute',
  'Grand Forks',
  'Grand Haven',
  'Grand Island',
  'Grand Island',
  'Grand Junction',
  'Grand Ledge',
  'Grand Prairie',
  'Grand Rapids',
  'Grand Rapids',
  'Grand Rapids',
  'Grand Terrace',
  'Grandview',
  'Grandview',
  'Grandview Heights',
  'Grandville',
  'Granger',
  'Granite Bay',
  'Granite City',
  'Grants',
  'Grants Pass',
  'Grantsville',
  'Granville',
  'Grapevine',
  'Grass Valley',
  'Gray',
  'Grayslake',
  'Great Barrington',
  'Great Bend',
  'Great Falls',
  'Great Falls',
  'Great Neck',
  'Great Neck Plaza',
  'Greater Carrollwood',
  'Greater Landover',
  'Greater Northdale',
  'Greater Sun Center',
  'Greater Upper Marlboro',
  'Greatwood',
  'Greece',
  'Greece',
  'Greeley',
  'Green',
  'Green',
  'Green Bay',
  'Green Haven',
  'Green Hill',
  'Green River',
  'Green Valley',
  'Green Valley',
  'Greenacres',
  'Greenbelt',
  'Greenburgh',
  'Greencastle',
  'Greendale',
  'Greeneville',
  'Greenfield',
  'Greenfield',
  'Greenfield',
  'Greenfield',
  'Greenfield',
  'Greenfield',
  'Greenlawn',
  'Greensboro',
  'Greensburg',
  'Greensburg',
  'Greentree',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenville',
  'Greenwich',
  'Greenwood',
  'Greenwood',
  'Greenwood',
  'Greenwood',
  'Greenwood Village',
  'Greer',
  'Grenada',
  'Gresham',
  'Gresham Park',
  'Gretna',
  'Griffin',
  'Griffith',
  'Grinnell',
  'Griswold',
  'Groesbeck',
  'Grosse Ile',
  'Grosse Pointe Farms',
  'Grosse Pointe Park',
  'Grosse Pointe Woods',
  'Groton',
  'Groton',
  'Groton',
  'Grove City',
  'Grove City',
  'Groveland',
  'Grover Beach',
  'Groves',
  'Groveton',
  'Grovetown',
  'Guilderland',
  'Guilford',
  'Gulf Gate Estates',
  'Gulfport',
  'Gulfport',
  'Gunbarrel',
  'Guntersville',
  'Gurnee',
  'Guthrie',
  'Guttenberg',
  'Guymon',
  'Hacienda Heights',
  'Hackensack',
  'Hackettstown',
  'Haddam',
  'Haddon Heights',
  'Haddonfield',
  'Hagerstown',
  'Haiku-Pauwela',
  'Hailey',
  'Haines City',
  'Halawa',
  'Haledon',
  'Hales Corners',
  'Half Moon',
  'Half Moon Bay',
  'Halfmoon',
  'Halfway',
  'Halifax',
  'Hallandale',
  'Haltom City',
  'Ham Lake',
  'Hamburg',
  'Hamburg',
  'Hamden',
  'Hamilton',
  'Hamilton',
  'Hamilton',
  'Hamlet',
  'Hamlin',
  'Hammond',
  'Hammond',
  'Hammonton',
  'Hampden',
  'Hampstead',
  'Hampton',
  'Hampton',
  'Hampton',
  'Hampton Bays',
  'Hampton Township',
  'Hamptons at Boca Raton',
  'Hamtramck',
  'Hanahan',
  'Hanford',
  'Hannibal',
  'Hanover',
  'Hanover',
  'Hanover',
  'Hanover',
  'Hanover',
  'Hanover Park',
  'Hanson',
  'Hapeville',
  'Harahan',
  'Harker Heights',
  'Harleysville',
  'Harlingen',
  'Harper Woods',
  'Harriman',
  'Harrisburg',
  'Harrisburg',
  'Harrison',
  'Harrison',
  'Harrison',
  'Harrison',
  'Harrison',
  'Harrison',
  'Harrison',
  'Harrison Township',
  'Harrisonburg',
  'Harrisonville',
  'Harrodsburg',
  'Hartford',
  'Hartford',
  'Hartford',
  'Hartford',
  'Hartford City',
  'Hartland',
  'Hartsdale',
  'Hartselle',
  'Hartsville',
  'Harvard',
  'Harvey',
  'Harvey',
  'Harwich',
  'Harwood Heights',
  'Hasbrouck Heights',
  'Haslett',
  'Hastings',
  'Hastings',
  'Hastings',
  'Hastings',
  'Hastings-on-Hudson',
  'Hatboro',
  'Hattiesburg',
  'Hauppauge',
  'Havelock',
  'Haverhill',
  'Haverstraw',
  'Haverstraw',
  'Havre',
  'Havre de Grace',
  'an Gardens',
  'an Paradise Park',
  'Hawthorn Woods',
  'Hawthorne',
  'Hawthorne',
  'Hayden',
  'Hayesville',
  'Hays',
  'Haysville',
  'Hayward',
  'Hazel Crest',
  'Hazel Dell North',
  'Hazel Dell South',
  'Hazel Park',
  'Hazelwood',
  'Hazleton',
  'Healdsburg',
  'Heath',
  'Heber',
  'Heber Springs',
  'Hebron',
  'Helena',
  'Helena',
  'Helena',
  'Helena Valley Southeast',
  'Helena Valley West Central',
  'Hemet',
  'Hempstead',
  'Hempstead',
  'Henderson',
  'Henderson',
  'Henderson',
  'Henderson',
  'Hendersonville',
  'Hendersonville',
  'Henrietta',
  'Henryetta',
  'Hercules',
  'Hereford',
  'Herkimer',
  'Herkimer',
  'Hermantown',
  'Hermiston',
  'Hermitage',
  'Hermosa Beach',
  'Hernando',
  'Hernando',
  'Herndon',
  'Herrin',
  'Hershey',
  'Hesperia',
  'Hewitt',
  'Hewlett',
  'Hialeah',
  'Hialeah Gardens',
  'Hiawatha',
  'Hibbing',
  'Hickory',
  'Hickory Hills',
  'Hicksville',
  'Hidalgo',
  'High Point',
  'Highland',
  'Highland',
  'Highland',
  'Highland',
  'Highland Heights',
  'Highland Heights',
  'Highland Park',
  'Highland Park',
  'Highland Park',
  'Highland Park',
  'Highland Springs',
  'Highland Village',
  'Highlands',
  'Highlands',
  'Highlands Ranch',
  'Highview',
  'Hillcrest',
  'Hillcrest Heights',
  'Hilliard',
  'Hillsboro',
  'Hillsboro',
  'Hillsboro',
  'Hillsborough',
  'Hillsdale',
  'Hillsdale',
  'Hillside',
  'Hillside',
  'Hillview',
  'Hilo',
  'Hilton Head Island',
  'Hinesville',
  'Hingham',
  'Hinsdale',
  'Hitchcock',
  'Hobart',
  'Hobart',
  'Hobbs',
  'Hobe Sound',
  'Hoboken',
  'Hockessin',
  'Hoffman Estates',
  'Holbrook',
  'Holbrook',
  'Holbrook',
  'Holden',
  'Holiday',
  'Holiday City-Berkeley',
  'Holladay',
  'Holland',
  'Hollins',
  'Hollis',
  'Hollister',
  'Holliston',
  'Holly',
  'Holly Hill',
  'Holly Springs',
  'Holly Springs',
  'Hollywood',
  'Holmen',
  'Holt',
  'Holtsville',
  'Holualoa',
  'Holyoke',
  'Home Gardens',
  'Homeacre-Lyndora',
  'Homeland Park',
  'Homer',
  'Homestead',
  'Homestead Meadows South',
  'Homewood',
  'Homewood',
  'Homosassa Springs',
  'Hondo',
  'Honolulu',
  'Hooksett',
  'Hoosick',
  'Hoover',
  'Hopatcong',
  'Hope',
  'Hope Mills',
  'Hopewell',
  'Hopkins',
  'Hopkinsville',
  'Hopkinton',
  'Hopkinton',
  'Hoquiam',
  'Horn Lake',
  'Hornell',
  'Horseheads',
  'Horseheads',
  'Horsham',
  'Hot Springs',
  'Hot Springs Village',
  'Houghton',
  'Houlton',
  'Houma',
  'Houston',
  'Howard',
  'Howell',
  'Howland Center',
  'Hubbard',
  'Huber Heights',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson',
  'Hudson Falls',
  'Hudsonville',
  'Hueytown',
  'Hugo',
  'Hull',
  'Hull',
  'Humble',
  'Humboldt',
  'Hunters Creek',
  'Huntersville',
  'Huntingdon',
  'Huntington',
  'Huntington',
  'Huntington',
  'Huntington',
  'Huntington',
  'Huntington Beach',
  'Huntington Park',
  'Huntington Station',
  'Huntington Woods',
  'Huntsville',
  'Huntsville',
  'Hurley',
  'Huron',
  'Huron',
  'Huron',
  'Hurricane',
  'Hurst',
  'Hutchinson',
  'Hutchinson',
  'Hyattsville',
  'Hybla Valley',
  'Hyde Park',
  'Hyrum',
  'Idabel',
  'Idaho Falls',
  'Idylwood',
  'Ilion',
  'Immokalee',
  'Imperial',
  'Imperial Beach',
  'Incline Village-Crystal Bay',
  'Independence',
  'Independence',
  'Independence',
  'Independence',
  'Independence',
  'Independence',
  'Indian Harbour Beach',
  'Indian Trail',
  '',
  'polis',
  'Indianola',
  'Indianola',
  'Indio',
  'Ingleside',
  'Inglewood',
  'Inglewood-Finn Hill',
  'Inkster',
  'Interlaken',
  'International Falls',
  'Inver Grove Heights',
  'Inverness',
  'Inverness',
  'Inwood',
  'Inwood',
  'Iola',
  'Iona',
  'Ione',
  'Ionia',
  ' City',
  ' Park',
  'Ipswich',
  'Irmo',
  'Iron Mountain',
  'Irondale',
  'Irondale',
  'Irondequoit',
  'Irondequoit',
  'Ironton',
  'Ironwood',
  'Irvine',
  'Irving',
  'Irvington',
  'Irvington',
  'Iselin',
  'Ishpeming',
  'Isla Vista',
  'Islamorada',
  'Island Lake',
  'Islip',
  'Islip',
  'Issaquah',
  'Itasca',
  'Ithaca',
  'Ithaca',
  'Ives Estates',
  'Jacinto City',
  'Jackson',
  'Jackson',
  'Jackson',
  'Jackson',
  'Jackson',
  'Jackson',
  'Jacksonville',
  'Jacksonville',
  'Jacksonville',
  'Jacksonville',
  'Jacksonville',
  'Jacksonville',
  'Jacksonville Beach',
  'Jamesburg',
  'Jamestown',
  'Jamestown',
  'Janesville',
  'Jasmine Estates',
  'Jasper',
  'Jasper',
  'Jasper',
  'Jeannette',
  'Jefferson',
  'Jefferson',
  'Jefferson',
  'Jefferson City',
  'Jefferson City',
  'Jefferson Hills',
  'Jefferson Valley-Yorktown',
  'Jeffersontown',
  'Jeffersonville',
  'Jenison',
  'Jenks',
  'Jennings',
  'Jennings',
  'Jennings Lodge',
  'Jensen Beach',
  'Jericho',
  'Jerome',
  'Jersey City',
  'Jersey Village',
  'Jerseyville',
  'Jessup',
  'Jesup',
  'Johnson City',
  'Johnson City',
  'Johnston',
  'Johnston',
  'Johnstown',
  'Johnstown',
  'Johnstown',
  'Joliet',
  'Jollyville',
  'Jonesboro',
  'Joplin',
  'Joppatowne',
  'Junction City',
  'Juneau and',
  'Jupiter',
  'Justice',
  'Kahului',
  'Kailua',
  'Kailua',
  'Kalamazoo',
  'Kalaoa',
  'Kalispell',
  'Kaneohe',
  'Kaneohe Station',
  'Kankakee',
  'Kannapolis',
  'Kansas City',
  'Kansas City',
  'Kapaa',
  'Katy',
  'Kaufman',
  'Kaukauna',
  'Kaysville',
  'Keansburg',
  'Kearney',
  'Kearns',
  'Kearny',
  'Keene',
  'Keizer',
  'Keller',
  'Kelso',
  'Kemp Mill',
  'Ken Caryl',
  'Kenai',
  'Kendale Lakes',
  'Kendall',
  'Kendall Park',
  'Kendall West',
  'Kendallville',
  'Kenilworth',
  'Kenmore',
  'Kenmore',
  'Kennebunk',
  'Kennedy Township',
  'Kenner',
  'Kennesaw',
  'Kennett',
  'Kennewick',
  'Kenosha',
  'Kensington',
  'Kent',
  'Kent',
  'Kent',
  'Kentfield',
  'Kenton',
  'Kentwood',
  'Kenwood',
  'Keokuk',
  'Kerman',
  'Kernersville',
  'Kerrville',
  'Ketchikan',
  'Kettering',
  'Kettering',
  'Kewanee',
  'Key Biscayne',
  'Key Largo',
  'Key West',
  'Keyport',
  'Keystone',
  'Kihei',
  'Kilgore',
  'Killeen',
  'Killingly',
  'Killingworth',
  'Kimberly',
  'Kinderhook',
  'King City',
  'King of Prussia',
  'Kingman',
  'Kings Grant',
  'Kings Mountain',
  'Kings Park',
  'Kings Point',
  'Kingsburg',
  'Kingsbury',
  'Kingsgate',
  'Kingsland',
  'Kingsport',
  'Kingston',
  'Kingston',
  'Kingston',
  'Kingsville',
  'Kinnelon',
  'Kinston',
  'Kirby',
  'Kirkland',
  'Kirkland',
  'Kirksville',
  'Kirkwood',
  'Kirtland',
  'Kirtland',
  'Kiryas Joel',
  'Kissimmee',
  'Kittery',
  'Klamath Falls',
  'Knik-Fairview',
  'Knoxville',
  'Knoxville',
  'Kodiak',
  'Kokomo',
  'Kosciusko',
  'Kulpsville',
  'La Canada Flintridge',
  'La Crescenta-Montrose',
  'La Crosse',
  'La Fayette',
  'La Feria',
  'La Follette',
  'La Grande',
  'La Grange',
  'La Grange',
  'La Grange Park',
  'La Habra',
  'La Homa',
  'La Junta',
  'La Marque',
  'La Mesa',
  'La Mirada',
  'La Palma',
  'La Plata',
  'La Porte',
  'La Porte',
  'La Presa',
  'La Puente',
  'La Quinta',
  'La Riviera',
  'La Salle',
  'La Vergne',
  'La Verne',
  'La Vista',
  'Lacey',
  'Lackawanna',
  'Lackland AFB',
  'Lacombe',
  'Laconia',
  'Ladera Heights',
  'Ladson',
  'Ladue',
  'Lady Lake',
  'Lafayette',
  'Lafayette',
  'Lafayette',
  'Lafayette',
  'LaGrange',
  'Laguna',
  'Laguna Beach',
  'Laguna Hills',
  'Laguna Niguel',
  'Laguna West-Lakeside',
  'Laguna Woods',
  'Lahaina',
  'Lake Arbor',
  'Lake Arrowhead',
  'Lake Barcroft',
  'Lake Bluff',
  'Lake Butter',
  'Lake Carmel',
  'Lake Charles',
  'Lake City',
  'Lake City',
  'Lake Dallas',
  'Lake Elmo',
  'Lake Elsinore',
  'Lake Forest',
  'Lake Forest',
  'Lake Forest Park',
  'Lake Geneva',
  'Lake Grove',
  'Lake Havasu City',
  'Lake in the Hills',
  'Lake Jackson',
  'Lake Lorraine',
  'Lake Los Angeles',
  'Lake Lucerne',
  'Lake Magdalene',
  'Lake Mary',
  'Lake Mohawk',
  'Lake Monticello',
  'Lake Morton-Berrydale',
  'Lake Oswego',
  'Lake Park',
  'Lake Ridge',
  'Lake Ronkonkoma',
  'Lake Shore',
  'Lake Shore',
  'Lake St. Louis',
  'Lake Station',
  'Lake Stevens',
  'Lake Wales',
  'Lake Worth',
  'Lake Worth Corridor',
  'Lake Zurich',
  'Lakeland',
  'Lakeland',
  'Lakeland Highlands',
  'Lakeland North',
  'Lakeland South',
  'Lakes',
  'Lakes by the Bay',
  'Lakes of the Four Seasons',
  'Lakeside',
  'Lakeside',
  'Lakeside',
  'Lakeville',
  'Lakeville',
  'Lakeway',
  'Lakewood',
  'Lakewood',
  'Lakewood',
  'Lakewood',
  'Lakewood',
  'Lakewood Park',
  'Lamar',
  'Lambertville',
  'Lamesa',
  'Lamont',
  'Lampasas',
  'Lancaster',
  'Lancaster',
  'Lancaster',
  'Lancaster',
  'Lancaster',
  'Lancaster',
  'Lancaster',
  'Lancaster',
  'Land O’ Lakes',
  'Landen',
  'Lander',
  'Lanett',
  'Langley Park',
  'Lanham-Seabrook',
  'Lansdale',
  'Lansdowne',
  'Lansdowne-Baltimore Highlands',
  'Lansing',
  'Lansing',
  'Lansing',
  'Lansing',
  'Lantana',
  'Lapeer',
  'Laplace',
  'Laramie',
  'Larchmont',
  'Laredo',
  'Largo',
  'Largo',
  'Larkfield-Wikiup',
  'Larkspur',
  'Larose',
  'Las Cruces',
  'Las Vegas',
  'Las Vegas',
  'Lathrop',
  'Latrobe',
  'Lauderdale Lakes',
  'Lauderhill',
  'Laughlin',
  'Laurel',
  'Laurel',
  'Laurel',
  'Laurel',
  'Laurel',
  'Laurel Bay',
  'Laurence Harbor',
  'Laurens',
  'Laurinburg',
  'Lawndale',
  'Lawrence',
  'Lawrence',
  'Lawrence',
  'Lawrence',
  'Lawrenceburg',
  'Lawrenceburg',
  'Lawrenceville',
  'Lawton',
  'Layton',
  'Le Mars',
  'Le Ray',
  'Le Roy',
  'Lea Hill',
  'Leacock-Leola-Bareville',
  'League City',
  'Leander',
  'Leavenworth',
  'Leawood',
  'Lebanon',
  'Lebanon',
  'Lebanon',
  'Lebanon',
  'Lebanon',
  'Lebanon',
  'Lebanon',
  'Lebanon',
  'Ledyard',
  'Lee',
  'Lee’s Summit',
  'Leeds',
  'Leesburg',
  'Leesburg',
  'Leesville',
  'Lehi',
  'Lehigh Acres',
  'Leicester',
  'Leisure City',
  'Leisure Village West-Pine Lake Park',
  'Leitchfield',
  'Lemay',
  'Lemmon Valley-Golden Valley',
  'Lemon Grove',
  'Lemont',
  'Lemoore',
  'Lenexa',
  'Lennox',
  'Lenoir',
  'Lenoir City',
  'Lenox',
  'Leominster',
  'Leon Valley',
  'Leonia',
  'Levelland',
  'Levittown',
  'Levittown',
  'Lewisboro',
  'Lewisburg',
  'Lewiston',
  'Lewiston',
  'Lewiston',
  'Lewistown',
  'Lewisville',
  'Lewisville',
  'Lexington',
  'Lexington',
  'Lexington',
  'Lexington',
  'Lexington',
  'Lexington',
  'Lexington',
  'Lexington Park',
  'Lexington-Fayette',
  'Liberal',
  'Liberty',
  'Liberty',
  'Liberty',
  'Libertyville',
  'Lighthouse Point',
  'Lilburn',
  'Lima',
  'Lincoln',
  'Lincoln',
  'Lincoln',
  'Lincoln',
  'Lincoln',
  'Lincoln City',
  'Lincoln Park',
  'Lincoln Park',
  'Lincoln Village',
  'Lincolnia',
  'Lincolnshire',
  'Lincolnton',
  'Lincolnwood',
  'Lincroft',
  'Linda',
  'Linden',
  'Lindenhurst',
  'Lindenhurst',
  'Lindenwold',
  'Lindon',
  'Lindsay',
  'Linganore-Bartonsville',
  'Linglestown',
  'Lino Lakes',
  'Linthicum',
  'Linton Hall',
  'Linwood',
  'Lionville-Marchwood',
  'Lisbon',
  'Lisbon',
  'Lisle',
  'Litchfield',
  'Litchfield',
  'Litchfield',
  'Litchfield',
  'Lititz',
  'Little Canada',
  'Little Chute',
  'Little Cottonwood Creek Valley',
  'Little Falls',
  'Little Falls',
  'Little Ferry',
  'Little River',
  'Little Rock',
  'Little Silver',
  'Littlefield',
  'Littleton',
  'Littleton',
  'Live Oak',
  'Live Oak',
  'Live Oak',
  'Live Oak',
  'Livermore',
  'Livingston',
  'Livingston',
  'Livingston',
  'Livonia',
  'Livonia',
  'Lloyd',
  'Lochearn',
  'Lock Haven',
  'Lockhart',
  'Lockhart',
  'Lockport',
  'Lockport',
  'Lockport',
  'Lodi',
  'Lodi',
  'Logan',
  'Logan',
  'Logansport',
  'Loma Linda',
  'Lombard',
  'Lomita',
  'Lompoc',
  'London',
  'Londonderry',
  'Londonderry',
  'Londontowne',
  'Long Beach',
  'Long Beach',
  'Long Beach',
  'Long Branch',
  'Long Grove',
  'Longboat Key',
  'Longmeadow',
  'Longmeadow',
  'Longmont',
  'Longview',
  'Longview',
  'Longwood',
  'Loomis',
  'Lorain',
  'Lorton',
  'Los Alamitos',
  'Los Alamos',
  'Los Altos',
  'Los Altos Hills',
  'Los Angeles',
  'Los Banos',
  'Los Gatos',
  'Los Lunas',
  'Louisville',
  'Louisville',
  'Louisville',
  'Louisville',
  'Loveland',
  'Loveland',
  'Loves Park',
  'Lovington',
  'Lowell',
  'Lowell',
  'Lower Allen',
  'Lower Burrell',
  'Lubbock',
  'Lucas Valley-Marinwood',
  'Ludington',
  'Ludlow',
  'Lufkin',
  'Lugoff',
  'Luling',
  'Lumberton',
  'Lumberton',
  'Lunenburg',
  'Lutherville-Timonium',
  'Lutz',
  'Lynbrook',
  'Lynchburg',
  'Lynden',
  'Lyndhurst',
  'Lyndhurst',
  'Lyndon',
  'Lynn',
  'Lynn Haven',
  'Lynnfield',
  'Lynnfield',
  'Lynnwood',
  'Lynwood',
  'Lynwood',
  'Lyons',
  'Lysander',
  'Mableton',
  'Macedon',
  'Macedonia',
  'Machesney Park',
  'Macomb',
  'Macon',
  'Madeira',
  'Madera',
  'Madera Acres',
  'Madison',
  'Madison',
  'Madison',
  'Madison',
  'Madison',
  'Madison',
  'Madison',
  'Madison',
  'Madison Heights',
  'Madison Heights',
  'Madison Park',
  'Madisonville',
  'Magalia',
  'Magna',
  'Magnolia',
  'Mahopac',
  'Mahtomedi',
  'Maitland',
  'Makaha',
  'Makakilo City',
  'Makawao',
  'Malden',
  'Malibu',
  'Malone',
  'Malone',
  'Malta',
  'Maltby',
  'Malvern',
  'Malverne',
  'Mamakating',
  'Mamaroneck',
  'Mamaroneck',
  'Mammoth Lakes',
  'Manasquan',
  'Manassas',
  'Manassas Park',
  'Manchester',
  'Manchester',
  'Manchester',
  'Manchester',
  'Manchester',
  'Mandan',
  'Mandeville',
  'Mango',
  'Manhasset',
  'Manhattan',
  'Manhattan Beach',
  'Manistee',
  'Manitowoc',
  'Mankato',
  'Manlius',
  'Manorhaven',
  'Manorville',
  'Mansfield',
  'Mansfield',
  'Mansfield',
  'Mansfield',
  'Mansfield Center',
  'Manteca',
  'Manteno',
  'Mantua',
  'Manville',
  'Maple Glen',
  'Maple Grove',
  'Maple Heights',
  'Maple Valley',
  'Maplewood',
  'Maplewood',
  'Maplewood',
  'Maquoketa',
  'Marana',
  'Marathon',
  'Marblehead',
  'Marblehead',
  'Marcellus',
  'Marco Island',
  'Marcy',
  'Marengo',
  'Margate',
  'Margate City',
  'Marianna',
  'Marietta',
  'Marietta',
  'Marina',
  'Marina del Rey',
  'Marinette',
  'Marion',
  'Marion',
  'Marion',
  'Marion',
  'Marion',
  'Marion',
  'Marion',
  'Markham',
  'Marlborough',
  'Marlborough',
  'Marlin',
  'Marlow Heights',
  'Marlton',
  'Marlton',
  'Marquette',
  'Marrero',
  'Marshall',
  'Marshall',
  'Marshall',
  'Marshall',
  'Marshalltown',
  'Marshfield',
  'Marshfield',
  'Martha Lake',
  'Martin',
  'Martinez',
  'Martinez',
  'Martins Ferry',
  'Martinsburg',
  'Martinsville',
  'Martinsville',
  ' City',
  ' Heights',
  'Marysville',
  'Marysville',
  'Marysville',
  'Marysville',
  'Maryville',
  'Maryville',
  'Mashpee',
  'Mason',
  'Mason',
  'Mason City',
  'Masonboro',
  'Massapequa',
  'Massapequa Park',
  'Massena',
  'Massena',
  'Massillon',
  'Mastic',
  'Mastic Beach',
  'Matawan',
  'Mattapoisett',
  'Matteson',
  'Matthews',
  'Mattoon',
  'Mattydale',
  'Mauldin',
  'Maumee',
  'Maumelle',
  'Mayfield',
  'Mayfield',
  'Mayfield Heights',
  'Maynard',
  'Maynard',
  'Mays Chapel',
  'Maysville',
  'Maywood',
  'Maywood',
  'Maywood',
  'McAlester',
  'McAllen',
  'McCandless Township',
  'McComb',
  'McCook',
  'McDonough',
  'McFarland',
  'McFarland',
  'McGregor',
  'McGuire AFB',
  'McHenry',
  'McKees Rocks',
  'McKeesport',
  'McKinleyville',
  'McKinney',
  'McLean',
  'McMinnville',
  'McMinnville',
  'McPherson',
  'Meadow Woods',
  'Meadville',
  'Mebane',
  'Mechanicsburg',
  'Mechanicstown',
  'Mechanicsville',
  'Medfield',
  'Medfield',
  'Medford',
  'Medford',
  'Medford',
  'Medina',
  'Medina',
  'Medulla',
  'Medway',
  'Mehlville',
  'Melbourne',
  'Melrose',
  'Melrose Park',
  'Melrose Park',
  'Melville',
  'Melvindale',
  'Memphis',
  'Memphis',
  'Menasha',
  'Menasha',
  'Mendon',
  'Mendota',
  'Mendota',
  'Mendota Heights',
  'Menlo Park',
  'Menominee',
  'Menomonee Falls',
  'Menomonie',
  'Mentone',
  'Mentor',
  'Mentor-on-the-Lake',
  'Mequon',
  'Meraux',
  'Merced',
  'Mercedes',
  'Mercer Island',
  'Mercerville-Hamilton Square',
  'Meriden',
  'Meriden',
  'Meridian',
  'Meridian',
  'Merriam',
  'Merrick',
  'Merrifield',
  'Merrill',
  'Merrillville',
  'Merrimac',
  'Merrimack',
  'Merritt Island',
  'Merrydale',
  'Merton',
  'Mesa',
  'Mesquite',
  'Mesquite',
  'Metairie',
  'Methuen',
  'Metropolis',
  'Metuchen',
  'Mexia',
  'Mexico',
  'Miami',
  'Miami',
  'Miami Beach',
  'Miami Lakes',
  'Miami Shores',
  'Miami Springs',
  'Miamisburg',
  'Micco',
  ' City',
  'Middle Island',
  'Middle River',
  'Middle Valley',
  'Middleborough',
  'Middleborough Center',
  'Middleburg',
  'Middleburg Heights',
  'Middlebury',
  'Middlebury',
  'Middlebury',
  'Middlesborough',
  'Middlesex',
  'Middleton',
  'Middleton',
  'Middletown',
  'Middletown',
  'Middletown',
  'Middletown',
  'Middletown',
  'Middletown',
  'Middletown',
  'Middletown',
  'Midland',
  'Midland',
  'Midland',
  'Midland Park',
  'Midlothian',
  'Midlothian',
  'Midvale',
  'Midwest City',
  'Milan',
  'Miles City',
  'Milford',
  'Milford',
  'Milford',
  'Milford',
  'Milford',
  'Milford',
  'Milford',
  'Milford',
  'Milford',
  'Milford Mill',
  'Mililani Town',
  'Mill Creek',
  'Mill Plain',
  'Mill Valley',
  'Millbrae',
  'Millbrook',
  'Millburn',
  'Millbury',
  'Millcreek',
  'Milledgeville',
  'Miller Place',
  'Millersville',
  'Millington',
  'Millis',
  'Milltown',
  'Millville',
  'Milo',
  'Milpitas',
  'Milton',
  'Milton',
  'Milton',
  'Milton',
  'Milton',
  'Milton',
  'Milton-Freewater',
  'Milwaukee',
  'Milwaukie',
  'Mims',
  'Minden',
  'Mineola',
  'Mineral Wells',
  'Minneapolis',
  'Minnehaha',
  'Minnetonka',
  'Minot',
  'Minot AFB',
  'Mint Hill',
  'Mira Loma',
  'Mira Monte',
  'Miramar',
  'Mishawaka',
  'Mission',
  'Mission',
  'Mission Bend',
  'Mission Viejo',
  'Missoula',
  ' City',
  'Mitchell',
  'Mitchellville',
  'Moberly',
  'Mobile',
  'Modesto',
  'Mohave Valley',
  'Mokena',
  'Moline',
  'Monaca',
  'Monahans',
  'Monessen',
  'Monett',
  'Monmouth',
  'Monmouth',
  'Monona',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroe',
  'Monroeville',
  'Monrovia',
  'Monsey',
  'Monson',
  'Montague',
  'Montclair',
  'Montclair',
  'Montclair',
  'Montebello',
  'Montecito',
  'Monterey',
  'Monterey Park',
  'Montgomery',
  'Montgomery',
  'Montgomery',
  'Montgomery Village',
  'Montgomeryville',
  'Monticello',
  'Monticello',
  'Monticello',
  'Montpelier',
  'Montrose',
  'Montrose',
  'Montvale',
  'Montville',
  'Moody',
  'Moore',
  'Moorestown-Lenola',
  'Mooresville',
  'Mooresville',
  'Moorhead',
  'Moorpark',
  'Moraga',
  'Moraine',
  'Moreau',
  'Morehead City',
  'Moreno Valley',
  'Morgan City',
  'Morgan Hill',
  'Morganton',
  'Morgantown',
  'Morganville',
  'Morrilton',
  'Morris',
  'Morristown',
  'Morristown',
  'Morrisville',
  'Morro Bay',
  'Morton',
  'Morton Grove',
  'Moscow',
  'Moses Lake',
  'Moss Bluff',
  'Moss Point',
  'Moultrie',
  'Mound',
  'Mounds View',
  'Moundsville',
  'Mount Airy',
  'Mount Airy',
  'Mount Carmel',
  'Mount Carmel',
  'Mount Clemens',
  'Mount Dora',
  'Mount Healthy',
  'Mount Holly',
  'Mount Hope',
  'Mount Ivy',
  'Mount Joy',
  'Mount Juliet',
  'Mount Kisco',
  'Mount Kisco',
  'Mount Lebanon',
  'Mount Olympus',
  'Mount Pleasant',
  'Mount Pleasant',
  'Mount Pleasant',
  'Mount Pleasant',
  'Mount Pleasant',
  'Mount Pleasant',
  'Mount Prospect',
  'Mount Rainier',
  'Mount Sinai',
  'Mount Vernon',
  'Mount Vernon',
  'Mount Vernon',
  'Mount Vernon',
  'Mount Vernon',
  'Mount Vernon',
  'Mount Washington',
  'Mountain Brook',
  'Mountain Home',
  'Mountain Home',
  'Mountain Home AFB',
  'Mountain Park',
  'Mountain Top',
  'Mountain View',
  'Mountainside',
  'Mountlake Terrace',
  'Mukilteo',
  'Mukwonago',
  'Mukwonago',
  'Muncie',
  'Mundelein',
  'Munhall',
  'Municipality of Monroeville',
  'Municipality of Murrysville',
  'Munster',
  'Murfreesboro',
  'Murphy',
  'Murphysboro',
  'Murray',
  'Murray',
  'Murray',
  'Murraysville',
  'Murrieta',
  'Muscatine',
  'Muscle Shoals',
  'Muscoy',
  'Muskego',
  'Muskegon',
  'Muskegon Heights',
  'Muskogee',
  'Mustang',
  'Myrtle Beach',
  'Myrtle Grove',
  'Myrtle Grove',
  'Mystic Island',
  'Nacogdoches',
  'Nampa',
  'Nanakuli',
  'Nanticoke',
  'Nantucket',
  'Nanuet',
  'Napa',
  'Naperville',
  'Napili-Honokowai',
  'Naples',
  'Naples Park',
  'Napoleon',
  'Nappanee',
  'Narragansett',
  'Nashua',
  'Nashville-Davidson',
  'Natchez',
  'Natchitoches',
  'Natick',
  'National City',
  'Naugatuck',
  'Naugatuck',
  'Navasota',
  'Nazareth',
  ' City',
  'Nederland',
  'Needham',
  'Needham',
  'Neenah',
  'Nellis AFB',
  'Neosho',
  'Neptune Beach',
  'Nesconset',
  'Nether Providence Township',
  'New Albany',
  'New Albany',
  'New Baltimore',
  'New Bedford',
  'New Berlin',
  'New Bern',
  'New Braunfels',
  'New Brighton',
  'New Brighton',
  'New Britain',
  'New Britain',
  'New Brunswick',
  'New Canaan',
  'New Carrollton',
  'New Cassel',
  'New Castle',
  'New Castle',
  'New Castle',
  'New City',
  'New Cumberland',
  'New Fairfield',
  'New Hartford',
  'New Hartford',
  'New Haven',
  'New Haven',
  'New Haven',
  'New Hope',
  'New Hyde Park',
  'New Iberia',
  'New Kensington',
  'New Kingman-Butler',
  'New Lenox',
  'New London',
  'New London',
  'New London',
  'New Milford',
  'New Milford',
  'New Milford',
  'New Orleans',
  'New Paltz',
  'New Paltz',
  'New Philadelphia',
  'New Port Richey',
  'New Port Richey East',
  'New Providence',
  'New Richmond',
  'New River',
  'New Rochelle',
  'New Scotland',
  'New Smyrna Beach',
  'New Territory',
  'New Ulm',
  'New Windsor',
  'New Windsor',
  'Newark',
  'Newark',
  'Newark',
  'Newark',
  'Newark',
  'Newberg',
  'Newberry',
  'Newburg',
  'Newburgh',
  'Newburgh',
  'Newbury',
  'Newburyport',
  'Newcastle',
  'Newfane',
  'Newington',
  'Newington',
  'Newington',
  'Newman',
  'Newmarket',
  'Newnan',
  'Newport',
  'Newport',
  'Newport',
  'Newport',
  'Newport',
  'Newport',
  'Newport Beach',
  'Newport East',
  'Newport News',
  'Newstead',
  'Newton',
  'Newton',
  'Newton',
  'Newton',
  'Newton',
  'Newtown',
  'Niagara',
  'Niagara Falls',
  'Niceville',
  'Nicholasville',
  'Niles',
  'Niles',
  'Niles',
  'Nipomo',
  'Niskayuna',
  'Nitro',
  'Nixa',
  'Noblesville',
  'Nogales',
  'Norco',
  'Norcross',
  'Norfolk',
  'Norfolk',
  'Norfolk',
  'Norland',
  'Normal',
  'Norman',
  'Normandy Park',
  'Norridge',
  'Norristown',
  'North Adams',
  'North Amherst',
  'North Amityville',
  'North Andover',
  'North Andrews Gardens',
  'North Arlington',
  'North Atlanta',
  'North Attleborough',
  'North Attleborough Center',
  'North Auburn',
  'North Augusta',
  'North Aurora',
  'North Babylon',
  'North Bay Shore',
  'North Bay Village',
  'North Bellmore',
  'North Bellport',
  'North Bend',
  'North Bethesda',
  'North Braddock',
  'North Branch',
  'North Branford',
  'North Brunswick Township',
  'North Caldwell',
  'North Canton',
  'North Castle',
  'North Charleston',
  'North Chicago',
  'North College Hill',
  'North Creek',
  'North Decatur',
  'North Druid Hills',
  'North Elba',
  'North Fair Oaks',
  'North Fort Myers',
  'North Greenbush',
  'North Haledon',
  'North Haven',
  'North Haven',
  'North Hempstead',
  'North Highlands',
  'North Kensington',
  'North Kingstown',
  'North Las Vegas',
  'North Lauderdale',
  'North Laurel',
  'North Lindenhurst',
  'North Little Rock',
  'North Logan',
  'North Madison',
  'North Manchester',
  'North Mankato',
  'North Marysville',
  'North Massapequa',
  'North Merrick',
  'North Miami',
  'North Miami Beach',
  'North Myrtle Beach',
  'North New Hyde Park',
  'North Ogden',
  'North Olmsted',
  'North Palm Beach',
  'North Patchogue',
  'North Plainfield',
  'North Platte',
  'North Port',
  'North Potomac',
  'North Providence',
  'North Providence',
  'North Reading',
  'North Richland Hills',
  'North Ridgeville',
  'North Riverside',
  'North Royalton',
  'North Salt Lake',
  'North Sarasota',
  'North Smithfield',
  'North Springfield',
  'North St. Paul',
  'North Star',
  'North Syracuse',
  'North Tonawanda',
  'North Valley',
  'North Valley Stream',
  'North Vernon',
  'North Versailles',
  'North Wantagh',
  'Northampton',
  'Northampton',
  'Northborough',
  'Northborough',
  'Northbridge',
  'Northbrook',
  'Northbrook',
  'Northfield',
  'Northfield',
  'Northgate',
  'Northglenn',
  'Northlake',
  'Northport',
  'Northport',
  'Northridge',
  'Northridge',
  'Northview',
  'Northville',
  'Northwest Harborcreek',
  'Norton',
  'Norton',
  'Norton Shores',
  'Norwalk',
  'Norwalk',
  'Norwalk',
  'Norwalk',
  'Norwalk',
  'Norway',
  'Norwell',
  'Norwich',
  'Norwich',
  'Norwich',
  'Norwood',
  'Norwood',
  'Norwood',
  'Novato',
  'Novi',
  'Nutley',
  'Nyack',
  'O’Fallon',
  'O’Fallon',
  'O’Hara Township',
  'Oak Brook',
  'Oak Creek',
  'Oak Forest',
  'Oak Grove',
  'Oak Grove',
  'Oak Grove',
  'Oak Grove',
  'Oak Harbor',
  'Oak Hill',
  'Oak Hills',
  'Oak Hills Place',
  'Oak Island',
  'Oak Lawn',
  'Oak Park',
  'Oak Park',
  'Oak Ridge',
  'Oak Ridge',
  'Oakbrook',
  'Oakdale',
  'Oakdale',
  'Oakdale',
  'Oakdale',
  'Oakland',
  'Oakland',
  'Oakland Park',
  'Oakley',
  'Oakmont',
  'Oakton',
  'Oakville',
  'Oakville',
  'Oakwood',
  'Oatfield',
  'Oberlin',
  'Ocala',
  'Ocean Acres',
  'Ocean City',
  'Ocean City',
  'Ocean Pines',
  'Ocean Springs',
  'Oceano',
  'Oceanside',
  'Oceanside',
  'Ocoee',
  'Oconomowoc',
  'Oconomowoc',
  'Odenton',
  'Odessa',
  'Oelwein',
  'Offutt AFB',
  'Ogden',
  'Ogden',
  'Ogdensburg',
  'Oil City',
  'Oildale',
  'Ojai',
  'Ojus',
  'Okemos',
  'Oklahoma City',
  'Okmulgee',
  'Okolona',
  'Olathe',
  'Old Bridge',
  'Old Forge',
  'Old Lyme',
  'Old Orchard Beach',
  'Old Orchard Beach',
  'Old Saybrook',
  'Old Town',
  'Oldsmar',
  'Olean',
  'Olive Branch',
  'Olivehurst',
  'Olivette',
  'Olmsted Falls',
  'Olney',
  'Olney',
  'Olympia',
  'Olympia Heights',
  'Omaha',
  'On',
  'Oneida',
  'Oneonta',
  'Onondaga',
  'Ontario',
  'Ontario',
  'Ontario',
  'Opa-locka',
  'Opa-locka North',
  'Opal Cliffs',
  'Opelika',
  'Opelousas',
  'Opp',
  'Opportunity',
  'Oquirrh',
  'Oradell',
  'Orange',
  'Orange',
  'Orange',
  'Orange',
  'Orange',
  'Orange',
  'Orange City',
  'Orange Cove',
  'Orange Lake',
  'Orange Park',
  'Orangeburg',
  'Orangetown',
  'Orangevale',
  'Orchard Mesa',
  'Orchard Park',
  'Orchards',
  'Orcutt',
  'Oregon City',
  'Orem',
  'Orinda',
  'Orland',
  'Orland Hills',
  'Orland Park',
  'Orlando',
  'Orleans',
  'Orlovista',
  'Ormond Beach',
  'Ormond-By-The-Sea',
  'Oro Valley',
  'Orono',
  'Orono',
  'Orono',
  'Orosi',
  'Oroville',
  'Oroville East',
  'Orrville',
  'Osceola',
  'Oshkosh',
  'Oskaloosa',
  'Ossining',
  'Ossining',
  'Oswego',
  'Oswego',
  'Oswego',
  'Otis Orchards-East Farms',
  'Otsego',
  'Ottawa',
  'Ottawa',
  'Ottumwa',
  'Overland',
  'Overland Park',
  'Overlea',
  'Oviedo',
  'Owasso',
  'Owatonna',
  'Owego',
  'Owensboro',
  'Owings Mills',
  'Owosso',
  'Oxford',
  'Oxford',
  'Oxford',
  'Oxford',
  'Oxford',
  'Oxford',
  'Oxnard',
  'Oxon Hill-Glassmanor',
  'Oyster Bay',
  'Oyster Bay',
  'Ozark',
  'Ozark',
  'Pace',
  'Pacific Grove',
  'Pacifica',
  'Paducah',
  'Page',
  'Pahrump',
  'Paine Field-Lake Stickney',
  'Painesville',
  'Palatine',
  'Palatka',
  'Palestine',
  'Palisades Park',
  'Palm Bay',
  'Palm Beach',
  'Palm Beach Gardens',
  'Palm City',
  'Palm Coast',
  'Palm Desert',
  'Palm Harbor',
  'Palm River-Clair Mel',
  'Palm Springs',
  'Palm Springs',
  'Palm Valley',
  'Palmdale',
  'Palmer',
  'Palmetto',
  'Palmetto Estates',
  'Palmview South',
  'Palmyra',
  'Palmyra',
  'Palmyra',
  'Palo Alto',
  'Palos Heights',
  'Palos Hills',
  'Palos Verdes Estates',
  'Pampa',
  'Panama City',
  'Panama City Beach',
  'Panthersville',
  'Papillion',
  'Paradise',
  'Paradise',
  'Paradise Valley',
  'Paragould',
  'Paramount',
  'Paramus',
  'Paris',
  'Paris',
  'Paris',
  'Paris',
  'Park City',
  'Park City',
  'Park Forest',
  'Park Forest Village',
  'Park Hills',
  'Park Ridge',
  'Park Ridge',
  'Parker',
  'Parker',
  'Parkersburg',
  'Parkland',
  'Parkland',
  'Parkville',
  'Parkville',
  'Parkway-South Sacramento',
  'Parkwood',
  'Parlier',
  'Parma',
  'Parma',
  'Parma Heights',
  'Parole',
  'Parsons',
  'Pasadena',
  'Pasadena',
  'Pasadena',
  'Pascagoula',
  'Pasco',
  'Pass Christian',
  'Passaic',
  'Pataskala',
  'Patchogue',
  'Paterson',
  'Patterson',
  'Patterson',
  'Pauls Valley',
  'Paulsboro',
  'Pawling',
  'Pawtucket',
  'Payette',
  'Payson',
  'Payson',
  'Pea Ridge',
  'Peabody',
  'Peachtree City',
  'Pearl',
  'Pearl City',
  'Pearl River',
  'Pearland',
  'Pearsall',
  'Pecan Grove',
  'Pecos',
  'Pedley',
  'Peekskill',
  'Pekin',
  'Pelham',
  'Pelham',
  'Pelham',
  'Pelham',
  'Pell City',
  'Pella',
  'Pembroke',
  'Pembroke',
  'Pembroke Park',
  'Pembroke Pines',
  'Pendleton',
  'Pendleton',
  'Penfield',
  'Penn Hills',
  'Pennsauken',
  'Pennsville',
  'Pensacola',
  'Peoria',
  'Peoria',
  'Peoria Heights',
  'Pepper Pike',
  'Pepperell',
  'Perinton',
  'Perkasie',
  'Perris',
  'Perry',
  'Perry',
  'Perry',
  'Perry',
  'Perry Hall',
  'Perry Heights',
  'Perrysburg',
  'Perryton',
  'Perryville',
  'Perth Amboy',
  'Peru',
  'Peru',
  'Peru',
  'Petal',
  'Petaluma',
  'Petersburg',
  'Petoskey',
  'Pewaukee',
  'Pewaukee',
  'Pflugerville',
  'Pharr',
  'Phelps',
  'Phenix City',
  'Philadelphia',
  'Philadelphia',
  'Philipstown',
  'Phillipsburg',
  'Phoenix',
  'Phoenixville',
  'Picayune',
  'Pickerington',
  'Picnic Point-North Lynnwood',
  'Pico Rivera',
  'Picture Rocks',
  'Piedmont',
  'Pierre',
  'Pike Creek',
  'Pikesville',
  'Pikeville',
  'Pimmit Hills',
  'Pine Bluff',
  'Pine Castle',
  'Pine Hill',
  'Pine Hills',
  'Pinecrest',
  'Pinehurst',
  'Pinehurst',
  'Pinellas Park',
  'Pineville',
  'Pinewood',
  'Piney Green',
  'Pinole',
  'Piqua',
  'Pismo Beach',
  'Pitman',
  'Pittsburg',
  'Pittsburg',
  'Pittsburgh',
  'Pittsfield',
  'Pittsford',
  'Pittston',
  'Placentia',
  'Placerville',
  'Plainedge',
  'Plainfield',
  'Plainfield',
  'Plainfield',
  'Plainfield',
  'Plainview',
  'Plainview',
  'Plainville',
  'Plainville',
  'Plaistow',
  'Plano',
  'Plant City',
  'Plantation',
  'Plaquemine',
  'Plattekill',
  'Platteville',
  'Plattsburgh',
  'Plattsburgh',
  'Plattsmouth',
  'Pleasant Grove',
  'Pleasant Grove',
  'Pleasant Hill',
  'Pleasant Hills',
  'Pleasant Prairie',
  'Pleasant Valley',
  'Pleasanton',
  'Pleasanton',
  'Pleasantville',
  'Pleasantville',
  'Pleasure Ridge Park',
  'Plover',
  'Plum',
  'Plymouth',
  'Plymouth',
  'Plymouth',
  'Plymouth',
  'Plymouth',
  'Plymouth',
  'Plymouth',
  'Plymouth',
  'Plymouth Township',
  'Pocahontas',
  'Pocatello',
  'Poinciana',
  'Point Pleasant',
  'Pomfret',
  'Pomona',
  'Pompano Beach',
  'Pompano Beach Highlands',
  'Pompey',
  'Pompton Lakes',
  'Ponca City',
  'Pontiac',
  'Pontiac',
  'Pooler',
  'Poplar Bluff',
  'Poquoson',
  'Port Angeles',
  'Port Arthur',
  'Port Charlotte',
  'Port Chester',
  'Port Clinton',
  'Port Hueneme',
  'Port Huron',
  'Port Jefferson',
  'Port Jefferson Station',
  'Port Jervis',
  'Port Lavaca',
  'Port Neches',
  'Port Orange',
  'Port Orchard',
  'Port Salerno',
  'Port St. John',
  'Port St. Lucie',
  'Port Townsend',
  'Port Washington',
  'Port Washington',
  'Portage',
  'Portage',
  'Portage',
  'Portage Lakes',
  'Portales',
  'Porter',
  'Porterville',
  'Portland',
  'Portland',
  'Portland',
  'Portland',
  'Portland',
  'Portland',
  'Portola Hills',
  'Portsmouth',
  'Portsmouth',
  'Portsmouth',
  'Portsmouth',
  'Post Falls',
  'Poteau',
  'Potomac',
  'Potsdam',
  'Potsdam',
  'Pottstown',
  'Pottsville',
  'Poughkeepsie',
  'Poughkeepsie',
  'Poulsbo',
  'Poway',
  'Powder Springs',
  'Powell',
  'Prairie du Chien',
  'Prairie Ridge',
  'Prairie Village',
  'Pratt',
  'Prattville',
  'Prescott',
  'Prescott Valley',
  'Presque Isle',
  'Price',
  'Prichard',
  'Prien',
  'Princeton',
  'Princeton',
  'Princeton',
  'Princeton',
  'Princeton',
  'Princeton',
  'Princeton Meadows',
  'Prineville',
  'Prior Lake',
  'Progress',
  'Prospect',
  'Prospect Heights',
  'Prospect Park',
  'Providence',
  'Provo',
  'Prunedale',
  'Pryor Creek',
  'Pueblo',
  'Pueblo West',
  'Pukalani',
  'Pulaski',
  'Pulaski',
  'Pullman',
  'Punta Gorda',
  'Punxsutawney',
  'Putnam',
  'Putnam District',
  'Putnam Valley',
  'Puyallup',
  'Quakertown',
  'Quantico Station',
  'Quartz Hill',
  'Queensbury',
  'Quincy',
  'Quincy',
  'Quincy',
  'Raceland',
  'Racine',
  'Radcliff',
  'Radford',
  'Radnor Township',
  'Rahway',
  'Rainbow City',
  'Raleigh',
  'Ralston',
  'Ramapo',
  'Ramblewood',
  'Ramona',
  'Ramsey',
  'Ramsey',
  'Rancho Cordova',
  'Rancho Cucamonga',
  'Rancho Mirage',
  'Rancho Palos Verdes',
  'Rancho San Diego',
  'Rancho Santa Margarita',
  'Randallstown',
  'Randolph',
  'Randolph',
  'Rantoul',
  'Rapid City',
  'Rapid Valley',
  'Raritan',
  'Raton',
  'Ravenna',
  'Rawlins',
  'Raymond',
  'Raymondville',
  'Raymore',
  'Rayne',
  'Raynham',
  'Raytown',
  'Reading',
  'Reading',
  'Reading',
  'Reading',
  'Red Bank',
  'Red Bank',
  'Red Bank',
  'Red Bluff',
  'Red Hill',
  'Red Hook',
  'Red Lion',
  'Red Oak',
  'Red Wing',
  'Redan',
  'Redding',
  'Redding',
  'Redford',
  'Redland',
  'Redlands',
  'Redlands',
  'Redmond',
  'Redmond',
  'Redondo Beach',
  'Redwood City',
  'Reedley',
  'Reedsburg',
  'Rehoboth',
  'Reidsville',
  'Reisterstown',
  'Rendon',
  'Reno',
  'Rensselaer',
  'Renton',
  'Republic',
  'Reserve',
  'Reston',
  'Revere',
  'Rexburg',
  'Reynoldsburg',
  'Rhinebeck',
  'Rhinelander',
  'Rialto',
  'Rib Mountain',
  'Rib Mountain',
  'Rice Lake',
  'Richardson',
  'Richboro',
  'Richfield',
  'Richfield',
  'Richfield',
  'Richland',
  'Richland',
  'Richland Hills',
  'Richmond',
  'Richmond',
  'Richmond',
  'Richmond',
  'Richmond',
  'Richmond',
  'Richmond',
  'Richmond Heights',
  'Richmond Heights',
  'Richmond Heights',
  'Richmond Hill',
  'Richmond West',
  'Richton Park',
  'Ridge',
  'Ridgecrest',
  'Ridgefield',
  'Ridgefield',
  'Ridgefield',
  'Ridgefield Park',
  'Ridgeland',
  'Ridgeway',
  'Ridgewood',
  'Ridley Park',
  'Rifle',
  'Ringwood',
  'Rio del Mar',
  'Rio Grande City',
  'Rio Linda',
  'Rio Rancho',
  'Ripley',
  'Ripon',
  'Ripon',
  'Rittman',
  'River Edge',
  'River Falls',
  'River Forest',
  'River Grove',
  'River Oaks',
  'River Ridge',
  'River Rouge',
  'River Vale',
  'Riverbank',
  'Riverdale',
  'Riverdale',
  'Riverdale',
  'Riverdale Park',
  'Riverhead',
  'Riverhead',
  'Riverside',
  'Riverside',
  'Riverside',
  'Riverside',
  'Riverton',
  'Riverton',
  'Riverton-Boulevard Park',
  'Riverview',
  'Riverview',
  'Riviera Beach',
  'Riviera Beach',
  'Roanoke',
  'Roanoke',
  'Roanoke Rapids',
  'Robbins',
  'Robbinsdale',
  'Robinson',
  'Robinson',
  'Robinson Township',
  'Robstown',
  'Rochelle',
  'Rochester',
  'Rochester',
  'Rochester',
  'Rochester',
  'Rochester',
  'Rochester',
  'Rochester Hills',
  'Rock Falls',
  'Rock Hill',
  'Rock Island',
  'Rock Springs',
  'Rockaway',
  'Rockcreek',
  'Rockford',
  'Rockingham',
  'Rockland',
  'Rockland',
  'Rockledge',
  'Rocklin',
  'Rockport',
  'Rockport',
  'Rockville',
  'Rockville',
  'Rockville Centre',
  'Rockwall',
  'Rocky Hill',
  'Rocky Mount',
  'Rocky Point',
  'Rocky River',
  'Rodeo',
  'Roeland Park',
  'Rogers',
  'Rohnert Park',
  'Rolla',
  'Rolling Hills Estates',
  'Rolling Meadows',
  'Roma',
  'Rome',
  'Rome',
  'Romeoville',
  'Romulus',
  'Ronkonkoma',
  'Roosevelt',
  'Rosamond',
  'Rosaryville',
  'Roscoe',
  'Rose Hill',
  'Roseburg',
  'Rosedale',
  'Rosedale',
  'Roseland',
  'Roselle',
  'Roselle',
  'Roselle Park',
  'Rosemead',
  'Rosemont',
  'Rosemount',
  'Rosenberg',
  'Rosendale',
  'Roseville',
  'Roseville',
  'Roseville',
  'Roslyn Heights',
  'Ross Township',
  'Rossford',
  'Rossmoor',
  'Rossmoor',
  'Rossville',
  'Roswell',
  'Roswell',
  'Rotonda',
  'Rotterdam',
  'Rotterdam',
  'Round Lake Beach',
  'Round Lake Park',
  'Round Rock',
  'Rowland Heights',
  'Rowlett',
  'Roxboro',
  'Roy',
  'Royal Oak',
  'Royal Palm Beach',
  'Royalton',
  'Rubidoux',
  'Ruidoso',
  'Rumford',
  'Rumson',
  'Runnemede',
  'Ruskin',
  'Russellville',
  'Russellville',
  'Russellville',
  'Ruston',
  'Rutherford',
  'Rutland',
  'Rutland',
  'Rye',
  'Rye',
  'Rye Brook',
  'Sachse',
  'Saco',
  'Sacramento',
  'Saddle Brook',
  'Safety Harbor',
  'Safford',
  'Saginaw',
  'Saginaw',
  'Saginaw Township North',
  'Saginaw Township South',
  'Saks',
  'Salamanca',
  'Salem',
  'Salem',
  'Salem',
  'Salem',
  'Salem',
  'Salem',
  'Salem',
  'Salem',
  'Salida',
  'Salina',
  'Salina',
  'Salinas',
  'Saline',
  'Salisbury',
  'Salisbury',
  'Salisbury',
  'Salisbury',
  'Sallisaw',
  'Salmon Creek',
  'Salt Lake City',
  'Sammamish',
  'San Angelo',
  'San Anselmo',
  'San Antonio',
  'San Benito',
  'San Bernardino',
  'San Bruno',
  'San Buenaventura',
  'San Carlos',
  'San Carlos Park',
  'San Clemente',
  'San Diego',
  'San Diego Country Estates',
  'San Dimas',
  'San Elizario',
  'San Fernando',
  'San Francisco',
  'San Gabriel',
  'San Jacinto',
  'San Jose',
  'San Juan',
  'San Juan Capistrano',
  'San Leandro',
  'San Lorenzo',
  'San Luis',
  'San Luis Obispo',
  'San Marcos',
  'San Marcos',
  'San Marino',
  'San Mateo',
  'San Pablo',
  'San Rafael',
  'San Ramon',
  'Sanatoga',
  'Sand Lake',
  'Sand Springs',
  'Sandalfoot Cove',
  'Sandersville',
  'Sandpoint',
  'Sandusky',
  'Sandusky South',
  'Sandwich',
  'Sandwich',
  'Sandy',
  'Sandy Springs',
  'Sanford',
  'Sanford',
  'Sanford',
  'Sanford',
  'Sanger',
  'Sanibel',
  'Sans Souci',
  'Santa Ana',
  'Santa Barbara',
  'Santa Clara',
  'Santa Clarita',
  'Santa Cruz',
  'Santa Fe',
  'Santa Fe',
  'Santa Fe Springs',
  'Santa Maria',
  'Santa Monica',
  'Santa Paula',
  'Santa Rosa',
  'Santee',
  'Sappington',
  'Sapulpa',
  'Saraland',
  'Sarasota',
  'Sarasota Springs',
  'Saratoga',
  'Saratoga Springs',
  'Sartell',
  'Satellite Beach',
  'Saugerties',
  'Saugus',
  'Saugus',
  'Sauk Rapids',
  'Sauk Village',
  'Sault Ste. Marie',
  'Sausalito',
  'Savage',
  'Savage-Guilford',
  'Savannah',
  'Savannah',
  'Sayreville',
  'Sayville',
  'Scarborough',
  'Scarsdale',
  'Scarsdale',
  'Schaghticoke',
  'Schaumburg',
  'Schenectady',
  'Schererville',
  'Schertz',
  'Schiller Park',
  'Schodack',
  'Schofield Barracks',
  'Schroeppel',
  'Scituate',
  'Scituate',
  'Scotch Plains',
  'Scotchtown',
  'Scotia',
  'Scott',
  'Scott Lake',
  'Scott Township',
  'Scottdale',
  'Scotts Valley',
  'Scottsbluff',
  'Scottsboro',
  'Scottsburg',
  'Scottsdale',
  'Scranton',
  'Scriba',
  'Seabrook',
  'Seabrook',
  'Seaford',
  'Seaford',
  'Seagoville',
  'Seal Beach',
  'Searcy',
  'Seaside',
  'SeaTac',
  'Seattle',
  'Seattle Hill-Silver Firs',
  'Sebastian',
  'Sebastopol',
  'Sebring',
  'Secaucus',
  'Security-Widefield',
  'Sedalia',
  'Sedona',
  'Sedro-Woolley',
  'Seekonk',
  'Seguin',
  'Selah',
  'Selden',
  'Sellersburg',
  'Selma',
  'Selma',
  'Seminole',
  'Seminole',
  'Senatobia',
  'Seneca',
  'Seneca Falls',
  'Seneca Falls',
  'Setauket-East Setauket',
  'Seven Corners',
  'Seven Hills',
  'Seven Oaks',
  'Severn',
  'Severna Park',
  'Sevierville',
  'Seward',
  'Seymour',
  'Seymour',
  'Seymour',
  'Shady Hills',
  'Shafter',
  'Shaker Heights',
  'Shakopee',
  'Shaler Township',
  'Shamokin',
  'Sharon',
  'Sharon',
  'Sharonville',
  'Shasta Lake',
  'Shawangunk',
  'Shawano',
  'Shawnee',
  'Shawnee',
  'Sheboygan',
  'Sheboygan Falls',
  'Sheffield',
  'Sheffield Lake',
  'Shelburne',
  'Shelby',
  'Shelby',
  'Shelby',
  'Shelbyville',
  'Shelbyville',
  'Shelbyville',
  'Shelton',
  'Shelton',
  'Shelton',
  'Shenandoah',
  'Shepherdsville',
  'Sheridan',
  'Sherman',
  'Sherrelwood',
  'Sherwood',
  'Sherwood',
  'Shields',
  'Shiloh',
  'Shiloh',
  'Shiloh',
  'Shiprock',
  'Shirley',
  'Shirley',
  'Shively',
  'Shoreline',
  'Shoreview',
  'Shorewood',
  'Shorewood',
  'Shorewood',
  'Show Low',
  'Shreveport',
  'Shrewsbury',
  'Shrewsbury',
  'Sidney',
  'Sidney',
  'Sidney',
  'Sierra Madre',
  'Sierra Vista',
  'Sierra Vista Southeast',
  'Siesta Key',
  'Signal Hill',
  'Signal Mountain',
  'Sikeston',
  'Siler City',
  'Siloam Springs',
  'Silsbee',
  'Silver City',
  'Silver Spring',
  'Silver Springs Shores',
  'Silverdale',
  'Silverton',
  'Silvis',
  'Simi Valley',
  'Simpsonville',
  'Simsbury',
  'Sioux Center',
  'Sioux City',
  'Sioux Falls',
  'Sitka and',
  'Skaneateles',
  'Skidaway Island',
  'Skokie',
  'Skowhegan',
  'Skowhegan',
  'Slaton',
  'Sleepy Hollow',
  'Slidell',
  'Smithfield',
  'Smithfield',
  'Smithfield',
  'Smithfield',
  'Smiths',
  'Smithtown',
  'Smithtown',
  'Smyrna',
  'Smyrna',
  'Snellville',
  'Snohomish',
  'Snyder',
  'Socastee',
  'Socorro',
  'Socorro',
  'Soddy-Daisy',
  'Sodus',
  'Solana Beach',
  'Soledad',
  'Solon',
  'Solvay',
  'Somers',
  'Somers',
  'Somers',
  'Somers Point',
  'Somerset',
  'Somerset',
  'Somerset',
  'Somerset',
  'Somerset',
  'Somersworth',
  'Somerton',
  'Somerville',
  'Somerville',
  'Sonoma',
  'Souderton',
  'Sound Beach',
  'South Amboy',
  'South Bend',
  'South Berwick',
  'South Boston',
  'South Bradenton',
  'South Burlington',
  'South Charleston',
  'South Cleveland',
  'South Daytona',
  'South El Monte',
  'South Elgin',
  'South Euclid',
  'South Farmingdale',
  'South Gate',
  'South Gate',
  'South Hadley',
  'South Highpoint',
  'South Hill',
  'South Hill',
  'South Holland',
  'South Houston',
  'South Huntington',
  'South Jordan',
  'South Kensington',
  'South Kingstown',
  'South Lake Tahoe',
  'South Laurel',
  'South Lockport',
  'South Lyon',
  'South Miami',
  'South Miami Heights',
  'South Milwaukee',
  'South Monroe',
  'South Ogden',
  'South Orange',
  'South Oroville',
  'South Park Township',
  'South Pasadena',
  'South Patrick Shores',
  'South Plainfield',
  'South Portland',
  'South River',
  'South Salt Lake',
  'South San Francisco',
  'South San Gabriel',
  'South San Jose Hills',
  'South Sioux City',
  'South St. Paul',
  'South Valley',
  'South Venice',
  'South Whittier',
  'South Williamsport',
  'South Windsor',
  'South Yarmouth',
  'South Yuba City',
  'Southampton',
  'Southaven',
  'Southborough',
  'Southbridge',
  'Southbridge',
  'Southbury',
  'Southeast',
  'Southeast Arcadia',
  'Southern Pines',
  'Southfield',
  'Southgate',
  'Southgate',
  'Southglenn',
  'Southington',
  'Southlake',
  'Southold',
  'Southport',
  'Southport',
  'Southside',
  'Southwick',
  'Southwood Acres',
  'Spanaway',
  'Spanish Fork',
  'Spanish Lake',
  'Spanish Springs',
  'Sparks',
  'Sparta',
  'Spartanburg',
  'Spearfish',
  'Speedway',
  'Spencer',
  'Spencer',
  'Spencer',
  'Spokane',
  'Spotswood',
  'Spring',
  'Spring Creek',
  'Spring Hill',
  'Spring Hill',
  'Spring Lake',
  'Spring Lake Park',
  'Spring Valley',
  'Spring Valley',
  'Spring Valley',
  'Springboro',
  'Springdale',
  'Springdale',
  'Springdale',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springfield',
  'Springville',
  'St. Albans',
  'St. Albans',
  'St. Andrews',
  'St. Ann',
  'St. Anthony',
  'St. Augustine',
  'St. Charles',
  'St. Charles',
  'St. Charles',
  'St. Clair Shores',
  'St. Cloud',
  'St. Cloud',
  'St. Dennis',
  'St. Francis',
  'St. George',
  'St. Helens',
  'St. James',
  'St. John',
  'St. John',
  'St. Johns',
  'St. Johnsbury',
  'St. Johnsbury',
  'St. Joseph',
  'St. Joseph',
  'St. Louis',
  'St. Louis Park',
  'St. Martin',
  'St. Martinville',
  'St. Marys',
  'St. Marys',
  'St. Marys',
  'St. Matthews',
  'St. Michael',
  'St. Paul',
  'St. Pete Beach',
  'St. Peter',
  'St. Peters',
  'St. Petersburg',
  'St. Rose',
  'St. Simons',
  'St. Stephens',
  'Stafford',
  'Stafford',
  'Stamford',
  'Stamford',
  'Standish',
  'Stanford',
  'Stanton',
  'Starkville',
  'State College',
  'Statesboro',
  'Statesville',
  'Staunton',
  'Stayton',
  'Steamboat Springs',
  'Steger',
  'Steilacoom',
  'Stephenville',
  'Sterling',
  'Sterling',
  'Sterling',
  'Sterling Heights',
  'Steubenville',
  'Stevens Point',
  'Stickney',
  'Stillwater',
  'Stillwater',
  'Stillwater',
  'Stockbridge',
  'Stockton',
  'Stone Mountain',
  'Stonegate',
  'Stoneham',
  'Stoneham',
  'Stonington',
  'Stony Brook',
  'Stony Point',
  'Stony Point',
  'Storm Lake',
  'Storrs',
  'Stoughton',
  'Stoughton',
  'Stow',
  'Stowe Township',
  'Stratford',
  'Stratford',
  'Stratford',
  'Stratham',
  'Strathmore',
  'Stratmoor',
  'Streamwood',
  'Streator',
  'Streetsboro',
  'Strongsville',
  'Struthers',
  'Stuart',
  'Stuarts Draft',
  'Sturbridge',
  'Sturgeon Bay',
  'Sturgis',
  'Sturgis',
  'Stuttgart',
  'Suamico',
  'Succasunna-Kenvil',
  'Sudbury',
  'Sudley',
  'Suffern',
  'Suffield',
  'Suffolk',
  'Sugar Hill',
  'Sugar Land',
  'Sugarmill Woods',
  'Suisun City',
  'Suitland-Silver Hill',
  'Sullivan',
  'Sullivan',
  'Sulphur',
  'Sulphur Springs',
  'Summerfield',
  'Summerville',
  'Summit',
  'Summit',
  'Summit',
  'Summit Park',
  'Sumner',
  'Sumter',
  'Sun City',
  'Sun City',
  'Sun City West',
  'Sun Lakes',
  'Sun Prairie',
  'Sun Valley',
  'Sunbury',
  'Sunland Park',
  'Sunny Isles Beach',
  'Sunnyside',
  'Sunnyside',
  'Sunnyvale',
  'Sunrise',
  'Sunrise Manor',
  'Sunset',
  'Sunset Hills',
  'Superior',
  'Superior',
  'Surprise',
  'Susanville',
  'Sussex',
  'Sutherlin',
  'Sutton',
  'Suwanee',
  'Swainsboro',
  'Swampscott',
  'Swampscott',
  'Swansea',
  'Swansea',
  'Swanton',
  'Swanzey',
  'Swarthmore',
  'Sweden',
  'Sweet Home',
  'Sweetwater',
  'Sweetwater',
  'Swissvale',
  'Sycamore',
  'Sylacauga',
  'Sylvania',
  'Syosset',
  'Syracuse',
  'Syracuse',
  'Tacoma',
  'Taft',
  'Tahlequah',
  'Takoma Park',
  'Talladega',
  'Tallahassee',
  'Tallmadge',
  'Tallulah',
  'Tamalpais-Homestead Valley',
  'Tamaqua',
  'Tamarac',
  'Tamiami',
  'Tampa',
  'Tanque Verde',
  'Tappan',
  'Tarboro',
  'Tarpon Springs',
  'Tarrant',
  'Tarrytown',
  'Taunton',
  'Tavares',
  'Taylor',
  'Taylor',
  'Taylor',
  'Taylor Mill',
  'Taylors',
  'Taylorsville',
  'Taylorville',
  'Teaneck',
  'Teays Valley',
  'Tecumseh',
  'Tecumseh',
  'Tehachapi',
  'Tell City',
  'Temecula',
  'Tempe',
  'Temperance',
  'Temple',
  'Temple City',
  'Temple Hills',
  'Temple Terrace',
  'Templeton',
  'Tenafly',
  'Terrace Heights',
  'Terre Haute',
  'Terrell',
  'Terrytown',
  'Terryville',
  'Tewksbury',
  'Texarkana',
  'Texarkana',
  'Texas City',
  'The Colony',
  'The Crossings',
  'The Hammocks',
  'The Pinery',
  'The Village',
  'The Villages',
  'The Woodlands',
  'Theodore',
  'Thermalito',
  'Thibodaux',
  'Thief River Falls',
  'Thomaston',
  'Thomaston',
  'Thomasville',
  'Thomasville',
  'Thompson',
  'Thompson',
  'Thompsonville',
  'Thomson',
  'Thonotosassa',
  'Thornton',
  'Thousand Oaks',
  'Three Lakes',
  'Three Rivers',
  'Tiburon',
  'Tiffin',
  'Tifton',
  'Tigard',
  'Tillmans Corner',
  'Timberlake',
  'Timberlane',
  'Tinley Park',
  'Tinton Falls',
  'Tipp City',
  'Titusville',
  'Titusville',
  'Tiverton',
  'Tiverton',
  'Toccoa',
  'Toledo',
  'Tolland',
  'Tomah',
  'Tomball',
  'Toms River',
  'Tonawanda',
  'Tonawanda',
  'Tonawanda',
  'Tooele',
  'Topeka',
  'Toppenish',
  'Topsfield',
  'Topsham',
  'Topsham',
  'Torrance',
  'Torrington',
  'Torrington',
  'Totowa',
  'Town n Country',
  'Town and Country',
  'Townsend',
  'Towson',
  'Tracy',
  'Traverse City',
  'Travilah',
  'Treasure Island',
  'Trenton',
  'Trenton',
  'Trenton',
  'Trenton',
  'Trinidad',
  'Trinity',
  'Trooper',
  'Trophy Club',
  'Trotwood',
  'Troutdale',
  'Troy',
  'Troy',
  'Troy',
  'Troy',
  'Troy',
  'Troy',
  'Truckee',
  'Trumann',
  'Trumbull',
  'Trumbull',
  'Trussville',
  'Truth or Consequences',
  'Tualatin',
  'Tuba City',
  'Tuckahoe',
  'Tuckahoe',
  'Tucker',
  'Tucson',
  'Tucson Estates',
  'Tukwila',
  'Tulare',
  'Tullahoma',
  'Tulsa',
  'Tumwater',
  'Tupelo',
  'Turlock',
  'Turtle Creek',
  'Tuscaloosa',
  'Tuscumbia',
  'Tuskegee',
  'Tustin',
  'Tustin Foothills',
  'Twentynine Palms',
  'Twentynine Palms Base',
  'Twin Falls',
  'Twin Lakes',
  'Twin Rivers',
  'Twinsburg',
  'Two Rivers',
  'Tyler',
  'Tyngsborough',
  'Tysons Corner',
  'Ukiah',
  'Ulster',
  'Union',
  'Union',
  'Union',
  'Union',
  'Union Beach',
  'Union City',
  'Union City',
  'Union City',
  'Union City',
  'Union Hill-Novelty Hill',
  'Union Park',
  'Uniondale',
  'Uniontown',
  'Universal City',
  'University',
  'University City',
  'University Heights',
  'University Park',
  'University Park',
  'University Park',
  'University Place',
  'Upland',
  'Upper Arlington',
  'Upper Grand Lagoon',
  'Upper Providence Township',
  'Upper Saddle River',
  'Upper Sandusky',
  'Upper St. Clair',
  'Urbana',
  'Urbana',
  'Urbandale',
  'Utica',
  'Uvalde',
  'Uxbridge',
  'Vacaville',
  'Vadnais Heights',
  'Valdosta',
  'Valinda',
  'Valle Vista',
  'Vallejo',
  'Valley',
  'Valley Center',
  'Valley City',
  'Valley Cottage',
  'Valley Falls',
  'Valley Park',
  'Valley Station',
  'Valley Stream',
  'Valparaiso',
  'Valparaiso',
  'Valrico',
  'Van Buren',
  'Van Buren',
  'Van Wert',
  'Vancouver',
  'Vandalia',
  'Vandalia',
  'Vandenberg AFB',
  'Vashon',
  'Venice',
  'Venice Gardens',
  'Ventnor City',
  'Veradale',
  'Vermilion',
  'Vermillion',
  'Vernal',
  'Vernon',
  'Vernon',
  'Vernon',
  'Vernon Hills',
  'Vero Beach',
  'Vero Beach South',
  'Verona',
  'Verona',
  'Verona',
  'Versailles',
  'Vestal',
  'Vestavia Hills',
  'Vicksburg',
  'Victor',
  'Victoria',
  'Victorville',
  'Vidalia',
  'Vidor',
  'Vienna',
  'Vienna',
  'View Park-Windsor Hills',
  'Villa Hills',
  'Villa Park',
  'Village Green-Green Ridge',
  'Village Park',
  'Village St. George',
  'Villas',
  'Villas',
  'Ville Platte',
  'Vincennes',
  'Vincent',
  'Vineland',
  'Vineyard',
  'Vinings',
  'Vinita',
  'Vinton',
  'Violet',
  'Virginia Beach',
  'Visalia',
  'Vista',
  'Volney',
  'Wabash',
  'Waco',
  'Waconia',
  'Wade Hampton',
  'Wading River',
  'Wadsworth',
  'Waggaman',
  'Wagoner',
  'Wahiawa',
  'Wahpeton',
  'Waianae',
  'Waihee-Waiehu',
  'Wailuku',
  'Waimalu',
  'Waimea',
  'Waipahu',
  'Waipio',
  'Waite Park',
  'Wake Forest',
  'Wakefield',
  'Wakefield',
  'Wakefield-Peacedale',
  'Walden',
  'Waldorf',
  'Waldwick',
  'Walker',
  'Walker Mill',
  'Walla Walla',
  'Walled Lake',
  'Waller',
  'Wallingford',
  'Wallingford Center',
  'Wallington',
  'Wallkill',
  'Walnut',
  'Walnut Creek',
  'Walnut Grove',
  'Walnut Park',
  'Walpole',
  'Waltham',
  'Walworth',
  'Wanaque',
  'Wantagh',
  'Wapakoneta',
  'Wappinger',
  'Ware',
  'Ware',
  'Wareham',
  'Warner Robins',
  'Warr Acres',
  'Warren',
  'Warren',
  'Warren',
  'Warren',
  'Warren',
  'Warrensburg',
  'Warrensville Heights',
  'Warrenton',
  'Warrenville',
  'Warrington',
  'Warsaw',
  'Warwick',
  'Warwick',
  'Warwick',
  'Wasco',
  'Waseca',
  'Washington Terrace',
  'Washington Township',
  'Washougal',
  'Watauga',
  'Waterboro',
  'Waterbury',
  'Waterbury',
  'Waterford',
  'Waterford',
  'Waterford',
  'Waterford',
  'Waterloo',
  'Waterloo',
  'Waterloo',
  'Watertown',
  'Watertown',
  'Watertown',
  'Watertown',
  'Watertown',
  'Waterville',
  'Watervliet',
  'Watsonville',
  'Wauconda',
  'Waukegan',
  'Waukesha',
  'Waukesha',
  'Waunakee',
  'Waupun',
  'Wausau',
  'Wauseon',
  'Wauwatosa',
  'Waveland',
  'Waverly',
  'Waverly',
  'Wawarsing',
  'Wawayanda',
  'Waxahachie',
  'Waycross',
  'Wayland',
  'Wayne',
  'Wayne',
  'Waynesboro',
  'Waynesboro',
  'Waynesville',
  'Weare',
  'Weatherford',
  'Weatherford',
  'Webb City',
  'Webster',
  'Webster',
  'Webster',
  'Webster',
  'Webster City',
  'Webster Groves',
  'Weddington',
  'Weigelstown',
  'Weirton',
  'Wekiwa Springs',
  'Welby',
  'Welcome',
  'Wellesley',
  'Wellesley',
  'Wellington',
  'Wellington',
  'Wells',
  'Wells Branch',
  'Wellston',
  'Wellsville',
  'Wenatchee',
  'Wentzville',
  'Weslaco',
  'West Allis',
  'West and East Lealman',
  'West Athens',
  'West Babylon',
  'West Bend',
  'West Bloomfield Township',
  'West Boylston',
  'West Bridgewater',
  'West Caldwell',
  'West Carrollton City',
  'West Carson',
  'West Chester',
  'West Chicago',
  'West Columbia',
  'West Covina',
  'West Des Moines',
  'West Fargo',
  'West Frankfort',
  'West Freehold',
  'West Gate',
  'West Glens Falls',
  'West Goshen',
  'West Hartford',
  'West Hartford',
  'West Hattiesburg',
  'West Haven',
  'West Haven',
  'West Haven-Sylvan',
  'West Haverstraw',
  'West Helena',
  'West Hempstead',
  'West Hollywood',
  'West Islip',
  'West Jordan',
  'West Lafayette',
  'West Lake Stevens',
  'West Linn',
  'West Little River',
  'West Livingston',
  'West Long Branch',
  'West Melbourne',
  'West Memphis',
  'West Mifflin',
  'West Milford',
  'West Modesto',
  'West Monroe',
  'West ',
  'West Norriton',
  'West Odessa',
  'West Orange',
  'West Palm Beach',
  'West Paterson',
  'West Pensacola',
  'West Perrine',
  'West Plains',
  'West Point',
  'West Point',
  'West Point',
  'West Puente Valley',
  'West Richland',
  'West Sacramento',
  'West Seneca',
  'West Seneca',
  'West Slope',
  'West Springfield',
  'West Springfield',
  'West Springfield',
  'West St. Paul',
  'West University Place',
  'West Valley',
  'West Valley City',
  'West Vero Corridor',
  'West View',
  'West Warwick',
  'West Warwick',
  'West Whittier-Los Nietos',
  'West Yarmouth',
  'Westborough',
  'Westbrook',
  'Westbrook',
  'Westbury',
  'Westchase',
  'Westchester',
  'Westchester',
  'Westerly',
  'Westerly',
  'Western Springs',
  'Westerville',
  'Westfield',
  'Westfield',
  'Westfield',
  'Westford',
  'Westgate-Belvedere Homes',
  'Westlake',
  'Westlake Village',
  'Westland',
  'Westmere',
  'Westminster',
  'Westminster',
  'Westminster',
  'Westminster',
  'Westmont',
  'Westmont',
  'Westmoreland',
  'Weston',
  'Weston',
  'Weston',
  'Weston',
  'Westport',
  'Westport',
  'Westport',
  'Westview',
  'Westwego',
  'Westwood',
  'Westwood',
  'Westwood',
  'Westwood Lakes',
  'Wethersfield',
  'Wethersfield',
  'Weymouth',
  'Weymouth',
  'Wharton',
  'Wharton',
  'Wheat Ridge',
  'Wheatfield',
  'Wheaton',
  'Wheaton-Glenmont',
  'Wheelersburg',
  'Wheeling',
  'Wheeling',
  'White Bear Lake',
  'White Center',
  'White Horse',
  'White House',
  'White Marsh',
  'White Meadow Lake',
  'White Oak',
  'White Oak',
  'White Oak',
  'White Plains',
  'White Rock',
  'White Settlement',
  'Whitefish Bay',
  'Whitehall',
  'Whitehall',
  'Whitestown',
  'Whitewater',
  'Whitinsville',
  'Whitman',
  'Whitmore Lake',
  'Whitney',
  'Whittier',
  'Wichita',
  'Wichita Falls',
  'Wickliffe',
  'Wilbraham',
  'Wildomar',
  'Wildwood',
  'Wilkes-Barre',
  'Wilkins Township',
  'Wilkinsburg',
  'Willard',
  'Williamsburg',
  'Williamsburg',
  'Williamson',
  'Williamsport',
  'Williamstown',
  'Williamstown',
  'Willimantic',
  'Williston',
  'Williston',
  'Williston Park',
  'Willmar',
  'Willoughby',
  'Willoughby Hills',
  'Willow Grove',
  'Willow Street',
  'Willowbrook',
  'Willowbrook',
  'Willowick',
  'Willows',
  'Wilmette',
  'Wilmington',
  'Wilmington',
  'Wilmington',
  'Wilmington',
  'Wilmington',
  'Wilmington Island',
  'Wilmington Manor',
  'Wilna',
  'Wilson',
  'Wilson',
  'Wilsonville',
  'Wilton',
  'Wilton',
  'Wilton Manors',
  'Winchendon',
  'Winchester',
  'Winchester',
  'Winchester',
  'Winchester',
  'Winchester',
  'Winchester',
  'Winchester',
  'Windemere',
  'Winder',
  'Windham',
  'Windham',
  'Windham',
  'Windsor',
  'Windsor',
  'Windsor',
  'Windsor',
  'Windsor Locks',
  'Windsor Locks',
  'Winfield',
  'Winfield',
  'Winnemucca',
  'Winnetka',
  'Winona',
  'Winooski',
  'Winslow',
  'Winslow',
  'Winslow',
  'Winsted',
  'Winston',
  'Winston-Salem',
  'Winter Garden',
  'Winter Gardens',
  'Winter Haven',
  'Winter Park',
  'Winter Springs',
  'Winters',
  'Winthrop',
  'Winthrop',
  'Winthrop',
  'Winthrop Harbor',
  'Winton',
  'Rapids',
  'Wixom',
  'Woburn',
  'Wolcott',
  'Wolf Trap',
  'Wolfeboro',
  'Wonder Lake',
  'Wood Dale',
  'Wood River',
  'Wood-Ridge',
  'Woodbourne-Hyde Park',
  'Woodbridge',
  'Woodbridge',
  'Woodbridge',
  'Woodburn',
  'Woodbury',
  'Woodbury',
  'Woodbury',
  'Woodbury',
  'Woodbury',
  'Woodcrest',
  'Woodfield',
  'Woodhaven',
  'Woodinville',
  'Woodlake',
  'Woodland',
  'Woodland Park',
  'Woodlawn',
  'Woodlawn',
  'Woodlyn',
  'Woodmere',
  'Woodmere',
  'Woodmoor',
  'Woodmore',
  'Woodridge',
  'Woods Cross',
  'Woodstock',
  'Woodstock',
  'Woodstock',
  'Woodstock',
  'Woodward',
  'Woodway',
  'Woonsocket',
  'Wooster',
  'Worcester',
  'Worth',
  'Worthington',
  'Worthington',
  'Wrentham',
  'Wright',
  'Wright-Patterson AFB',
  'Wyandanch',
  'Wyandotte',
  'Wyckoff',
  'Wylie',
  'Wyndham',
  'Wynne',
  'Wyomissing',
  'Wytheville',
  'Xenia',
  'Yakima',
  'Yankton',
  'Yardville-Groveville',
  'Yarmouth',
  'Yarmouth',
  'Yazoo City',
  'Yeadon',
  'Yeehaw Junction',
  'Yonkers',
  'Yorba Linda',
  'York',
  'York',
  'York',
  'York',
  'Yorketown',
  'Yorkshire',
  'Yorktown',
  'Yorktown Heights',
  'Yorkville',
  'Youngstown',
  'Ypsilanti',
  'Yreka',
  'Yuba City',
  'Yucaipa',
  'Yucca Valley',
  'Yukon',
  'Yulee',
  'Yuma',
  'Zachary',
  'Zanesville',
  'Zephyrhills',
  'Zion',
  'Zionsville',
  'Zuni Pueblo'
]