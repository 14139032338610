import React from 'react';


function SearchResultsNo(props) {

    return (
        <div className="container backgroundColor">
            <h1>Trafficmon</h1>
            <p>Sorry, there are no search results for this selection.</p>
        </div>

    )
}

export default SearchResultsNo;